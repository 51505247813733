import { SectionHeader } from 'components';
import { RoutingPaths } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectConnectedAddress, selectVestingPools } from 'store';

import VestingPools from './VestingPools';

export const Vesting: React.FC = () => {
  const vestingPools = useSelector(selectVestingPools);
  const connectedAddress = useSelector(selectConnectedAddress);
  const title = 'Vesting';

  return (
    <>
      {connectedAddress ? (
        <div className="container section-container vesting">
          <div className="container-inner">
            <SectionHeader title={title}>
              <div className="mb-16">
                The tokens are released continuously with different linear rates
                possible over several periods according to the predefined
                vesting schedule.
              </div>
              <ul className="mb-16">
                <li className="mb-16">
                  The <strong>Claimable</strong> amount (total unvested/released
                  minus already claimed) can be claimed directly to your wallet.
                </li>
                <li>
                  You can also claim your unclaimed tokens by depositing them
                  directly into the game{' '}
                  <strong>up to the total unclaimed allocation</strong> - the{' '}
                  <strong>Total left</strong> amount (including tokens not yet
                  unvested/released) thus being able to spend the whole
                  allocation within the game without waiting for the tokens to
                  unvest (available in{' '}
                  <Link
                    className="btn-link font-bold"
                    to={RoutingPaths.Settings}
                  >
                    Game Settings
                  </Link>
                  ).
                </li>
              </ul>
            </SectionHeader>

            {vestingPools ? <VestingPools /> : ''}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
