import { isProduction } from 'helpers/environment.helper';
import { VestingPoolType } from 'helpers/vesting';
import {
  AllocationPoolConfig,
  AllocationPoolItemName,
  AllocationPoolName,
  AllocationPoolSubtype,
  AllocationPoolType,
  AppConfig,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolType,
  TokenName,
  VestingPoolConfig,
} from 'models';
import {
  LPTokenSymbol,
  MainTokenSymbol,
  StableTokenSymbol,
} from 'models/token.model';
import { NetworksParams } from 'config/networks';

// ALLOCATION
import skynityAllocationBUSDContractJSON from './contracts/allocation/SkynityAllocationVaultBUSD.json';
import skynityAllocationPublicBUSDContractJSON from './contracts/allocation/SkynityAllocationVaultPublicBUSD.json';
import skynityAllocationSeedUSDTContractJSON from './contracts/allocation/SkynityAllocationVaultSeedUSDT.json';
import skynityAllocationUSDTContractJSON from './contracts/allocation/SkynityAllocationVaultUSDT.json';
// GAME BRIDGE
import gameBridgeContractJSON from './contracts/game-bridge/GameBridge.json';
// LP
import SDTUSDTLPPairContractJSON from './contracts/lp/LPPair.json';
import SDTUSDTtokenLPContractJSON from './contracts/lp/LPToken.json';
// NFT
import NFTContactJSON from './contracts/nft/NFTContract.json';
// TOKEN
import SDTtokenContractJSON from './contracts/sdt/SDTToken.json';
// STABLECOIN
import SCBUSDTokenJSON from './contracts/stable/SCBUSDToken.json';
import SCUSDTTokenJSON from './contracts/stable/SCUSDTToken.json';
import StakingLPSDTUSDTLiquid from './contracts/staking/liquid/Staking_LP_Liquid.json';
// STAKING
import StakingSDTLiquid from './contracts/staking/liquid/Staking_Sdt_Liquid.json';
import StakingSDTLocked3M from './contracts/staking/locked/Staking_SDT_Locked_3m.json';
import StakingLPSDTUSDTLocked3M from './contracts/staking/locked/Staking_SdtBusd_Locked3m.json';
// VESTING
import vestingWithBridge1 from './contracts/vesting/VestingWithBridge1.json';
import vestingWithBridge2 from './contracts/vesting/VestingWithBridge2.json';

const permittedNetworks = [
  NetworksParams.BscMainnet,
  ...(isProduction() ? [] : [NetworksParams.BscTestnet]),
];

const primaryNetwork = NetworksParams.BscMainnet;

const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  // TODO: Update name, change contracts
  poolDescription: 'SDT-BUSD',
  poolName: StakingPoolName.LP,
  poolStakingTokenSymbol: LPTokenSymbol.SDTUSDT,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: StakingLPSDTUSDTLiquid,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: StakingLPSDTUSDTLocked3M,
    },
  ],
};

const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: 'SDT',
  poolName: StakingPoolName.SA,
  poolStakingTokenSymbol: MainTokenSymbol.SDT,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: StakingSDTLiquid,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: StakingSDTLocked3M,
    },
  ],
};

const pregamePrivateAllocationPoolBUSD: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Other,
  poolDescription: 'BUSD',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.BUSD,
  poolItems: [
    {
      poolItemName: AllocationPoolItemName.BUSDSkynityAllocation,
      poolItemContractJSON: skynityAllocationBUSDContractJSON,
      hasWhitelist: true,
      isActive: false,
    },
  ],
};
const pregamePrivateAllocationPoolUSDT: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Other,
  poolDescription: 'USDT',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.USDT,
  poolItems: [
    {
      poolItemName: AllocationPoolItemName.USDTSkynityAllocation,
      poolItemContractJSON: skynityAllocationUSDTContractJSON,
      hasWhitelist: true,
      isActive: true,
    },
  ],
};
const pregamePublicAllocationPoolBUSD: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Public,
  poolDescription: 'BUSD',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.BUSD,
  poolItems: [
    {
      poolItemName: AllocationPoolItemName.BUSDSkynityPublicAllocation,
      poolItemContractJSON: skynityAllocationPublicBUSDContractJSON,
      hasWhitelist: false,
      isActive: false,
    },
  ],
};
const pregameSeedAllocationPoolUSDT: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Seed,
  poolDescription: 'USDT',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.USDT,
  poolItems: [
    {
      poolItemName: AllocationPoolItemName.USDTSkynitySeedAllocation,
      poolItemContractJSON: skynityAllocationSeedUSDTContractJSON,
      hasWhitelist: true,
      isActive: true,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [LPStakingPool, SAStakingPool];
// TODO - change types
const activeVestingPools: VestingPoolConfig[] = [
  {
    poolType: VestingPoolType.Team,
    vestingContractJSON: vestingWithBridge1,
  },
  {
    poolType: VestingPoolType.Public,
    vestingContractJSON: vestingWithBridge2,
  },
];
const activeAllocationPools: AllocationPoolConfig[] = [
  pregamePrivateAllocationPoolUSDT,
  pregamePrivateAllocationPoolBUSD,
  pregamePublicAllocationPoolBUSD,
  pregameSeedAllocationPoolUSDT,
];

export const bscConfig: AppConfig = {
  skynityFee: '0.3%',
  minimumDepositSdt: 20,
  minimumWithdrawalSdt: 20,
  withdrawalFeeFraction: 0.02,
  withdrawalFeeMinSdt: 1,
  permittedNetworks,
  primaryNetwork,
  tokens: {
    [MainTokenSymbol.SDT]: {
      name: TokenName.Skynity,
      symbol: MainTokenSymbol.SDT,
      decimals: 18,
      tokenContractJSON: SDTtokenContractJSON,
    },
    [LPTokenSymbol.SDTUSDT]: {
      name: TokenName.LPSkynity,
      symbol: LPTokenSymbol.SDTUSDT,
      decimals: 18,
      tokenContractJSON: SDTUSDTtokenLPContractJSON,
    },
    [StableTokenSymbol.USDT]: {
      name: TokenName.Stablecoin,
      symbol: StableTokenSymbol.USDT,
      decimals: 18,
      tokenContractJSON: SCUSDTTokenJSON,
    },
    [StableTokenSymbol.BUSD]: {
      name: TokenName.Stablecoin,
      symbol: StableTokenSymbol.BUSD,
      decimals: 18,
      tokenContractJSON: SCBUSDTokenJSON,
    },
  },
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
  allocation: {
    pools: activeAllocationPools,
  },
  gameBridgeContractJSON,
  nftContractJSON: NFTContactJSON,
  LPPairs: {
    [LPTokenSymbol.SDTUSDT]: { contractJSON: SDTUSDTLPPairContractJSON },
  },
  additionalData: {
    tokenAddresses: {
      [MainTokenSymbol.SDT]: '0x87133beCEa974278F6CBCAA32fcE03E24Dc97cE3',
    },
    tokenChartLink:
      'https://www.geckoterminal.com/bsc/pools/0x7632626286c17475a91f39173c144cff9b115eca',
    tokenLink:
      'https://pancakeswap.finance/swap?inputCurrency=0x87133beCEa974278F6CBCAA32fcE03E24Dc97cE3&outputCurrency=0x5f39dd1bb6db20f3e792c4489f514794cac6392c',
    lpPairLink:
      'https://pancakeswap.finance/add/0x87133beCEa974278F6CBCAA32fcE03E24Dc97cE3/0x5f39DD1bB6Db20F3e792c4489F514794caC6392c',
    skyLandsNftMarketplaceLink: 'https://opensea.io/collection/skylands-3',
    skyLandsNftBridgeLink: 'https://app.mintlabz.io/bridge',
  },
};
