import airWarrior from 'assets/images/air-warrior.png';
import React from 'react';

export const GameLauncher: React.FC = () => (
  <div className="game-launcher">
    <img src={airWarrior} className="game-launcher-image" />
    <div>
      <h2 className="game-launcher-header">
        Join the new world of gaming. <span className="font-bold">SkyNity</span>
      </h2>
      {/* TODO: Text */}
      {/* <div className="game-launcher-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius ut
        ex sit amet tempus.
      </div> */}
    </div>
    <a
      href="https://skynity.io/nft-beta-intro"
      target="_blank"
      rel="noreferrer"
      className="btn btn-primary game-launcher-button"
    >
      Launch game
    </a>
  </div>
);
