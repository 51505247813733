import { fromWei, toWei } from 'helpers';
import { GameBridgeAccountData, TokenData } from 'models';
import { Contract } from 'web3-eth-contract';

export const getGameBridgeData = async (
  connectedAddress: string,
  tokenData: TokenData,
  gameBridgeContract: Contract,
  serverId: number
): Promise<GameBridgeAccountData> => {
  const accountData: GameBridgeAccountData = await gameBridgeContract.methods
    .getAccountData(serverId, connectedAddress)
    .call();

  // Pending deposit
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pendingDepositWei = (accountData.pendingDeposit as any)?.amount;
  const pendingDeposit = fromWei(pendingDepositWei, tokenData.decimals);

  // Pending withdrawal
  const pendingWithdrawalWei = accountData.pendingWithdrawal;
  const pendingWithdrawal = fromWei(pendingWithdrawalWei, tokenData.decimals);

  // Approved withdrawal
  const approvedWithdrawalWei = accountData.approvedWithdrawal;
  const approvedWithdrawal = fromWei(approvedWithdrawalWei, tokenData.decimals);

  return {
    pendingDeposit,
    pendingWithdrawal,
    approvedWithdrawal,
    isBlacklisted: accountData.isBlacklisted,
  };
};

export const waitForDepositAcceptance = async (
  gameBridgeContract: Contract,
  connectedAddress: string,
  serverId: number
): Promise<void> => {
  const pollingInterval = 4000;
  const maxRetries = 15;

  let retries = 0;

  await new Promise((resolve) => {
    const checkRecursive = async (): Promise<void> => {
      retries++;
      // console.log(`Retry ${retries}`);
      const pendingDeposit = await gameBridgeContract.methods
        .getPendingDeposit(serverId, connectedAddress)
        .call();
      // console.log(pendingDeposit);
      if (+pendingDeposit.amount && retries <= maxRetries)
        setTimeout(checkRecursive, pollingInterval);
      else resolve(null);
    };

    setTimeout(checkRecursive, pollingInterval);
  });
};

export const waitForWithdrawalInBridge = async (
  gameBridgeContract: Contract,
  connectedAddress: string,
  serverId: number,
  currentPendingWithdrawal: string
): Promise<void> => {
  const pollingInterval = 4000;
  const maxRetries = 15;

  let retries = 0;

  await new Promise((resolve) => {
    const checkRecursive = async (): Promise<void> => {
      retries++;
      // console.log(`Retry ${retries}`);
      const pendingWithdrawal = await gameBridgeContract.methods
        .getPendingWithdrawal(serverId, connectedAddress)
        .call();
      // console.log(pendingWithdrawal);
      if (
        pendingWithdrawal == currentPendingWithdrawal &&
        retries <= maxRetries
      )
        setTimeout(checkRecursive, pollingInterval);
      else resolve(null);
    };

    setTimeout(checkRecursive, pollingInterval);
  });
};

export const postDepositFromWallet = async (
  gameBridgeContract: Contract,
  depositValue: string,
  accountId: string,
  decimals: number,
  connectedAddress: string,
  serverId: number
): Promise<unknown> => {
  const depositValueWei = toWei(depositValue, decimals);

  // preflight check with "call" to return a possible error with reason message without posting transaction ("send" does not return a reason message in web3js 1.x)
  await gameBridgeContract.methods
    .deposit(serverId, depositValueWei, accountId)
    .call({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });

  return await gameBridgeContract.methods
    .deposit(serverId, depositValueWei, accountId)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};

export const postWithdrawToWallet = async (
  gameBridgeContract: Contract,
  connectedAddress: string,
  serverId: number
): Promise<unknown> => {
  // preflight check with "call" to return a possible error with reason message without posting transaction ("send" does not return a reason message in web3js 1.x)
  await gameBridgeContract.methods.withdraw(serverId).call({
    from: connectedAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  });

  return await gameBridgeContract.methods.withdraw(serverId).send({
    from: connectedAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  });
};
