/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import {
  isCorrectValue,
  MessageText,
  MessageType,
  numbersOnly,
  skynityConfig,
} from 'helpers';
import { TokenData } from 'models';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Spinner, Tab, Tooltip } from 'react-bootstrap';

interface GameAccountItemWithdrawTabContentProps {
  loading: boolean;
  waitingForWithdrawal: boolean;
  inAccountSdt: string;
  pending: string;
  tokenData: TokenData;
  onRequestWithdraw: (withdrawValue: string) => void;
}

export const GameAccountItemWithdrawTabContent: React.FC<
  GameAccountItemWithdrawTabContentProps
> = ({
  loading,
  waitingForWithdrawal,
  inAccountSdt,
  pending,
  tokenData,
  onRequestWithdraw,
}) => {
  useEffect(() => {
    if (!loading) {
      setWithdrawValue('0');
    }
  }, [loading]);

  const [withdrawValue, setWithdrawValue] = useState<string>('0');
  const withdrawValueBN = new BigNumber(withdrawValue);
  const inAccountBn = new BigNumber(inAccountSdt);

  const withdrawalFee =
    +withdrawValue * skynityConfig.withdrawalFeeFraction >
    skynityConfig.withdrawalFeeMinSdt
      ? +withdrawValue * skynityConfig.withdrawalFeeFraction
      : skynityConfig.withdrawalFeeMinSdt;
  const netWithdrawalValue =
    +withdrawValue > withdrawalFee ? +withdrawValue - withdrawalFee : 0;

  const inputDisabled = loading || inAccountBn.isEqualTo(0);
  const percentageButtonsDisabled = loading || inAccountBn.isEqualTo(0);

  const withdrawButtonDisabled =
    loading ||
    +inAccountSdt == 0 ||
    netWithdrawalValue == 0 ||
    +withdrawValue < skynityConfig.minimumWithdrawalSdt ||
    withdrawValueBN.isGreaterThan(inAccountBn);

  const onApplyPercentage = (percentage: number): void => {
    const roundedNumber = BigNumber(
      inAccountBn.times(percentage).div(100).toFixed(tokenData.decimals)
    ).toString(10);

    setWithdrawValue(roundedNumber);
  };

  const onWithdrawValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokenData.decimals)) {
      setWithdrawValue(value);
    }
  };

  return (
    <Tab.Pane eventKey="withdraw" unmountOnExit={true} mountOnEnter={true}>
      {waitingForWithdrawal ? (
        <Message messageType={MessageType.Primary}>
          <div className="d-flex align-items-center gap-3">
            <div>
              <Spinner></Spinner>
            </div>
            <div>
              Please wait for the current withdrawal request to process on the
              server.
            </div>
          </div>
        </Message>
      ) : (
        <div className="tab-content">
          <div className="unstake-section">
            <div className="unstake-section-description">
              Here you can request an SDT token withdrawal from the game. Your
              request should be processed within 24 hours. After it is accepted,
              you will be able to withdraw it into your wallet.
            </div>

            {+inAccountSdt > 0 ? (
              <>
                <div className="unstake-section-actions">
                  <div className="text-end">
                    <small>
                      {skynityConfig.minimumWithdrawalSdt &&
                        `min. ${skynityConfig.minimumWithdrawalSdt} SDT, `}
                      max: {inAccountSdt} SDT
                    </small>
                  </div>
                  <input
                    className="s-input"
                    placeholder="0.00"
                    onChange={onWithdrawValueChange}
                    value={withdrawValue}
                    onKeyPress={numbersOnly}
                    disabled={inputDisabled}
                  />

                  <PercentageButtons
                    disabled={percentageButtonsDisabled}
                    applyPercentage={(percentage: number): void =>
                      onApplyPercentage(percentage)
                    }
                  />

                  {!withdrawButtonDisabled && (
                    <div className="text-center">
                      <small>
                        Fee: {withdrawalFee} SDT, you will receive:{' '}
                        {netWithdrawalValue} SDT
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Withdrawal fee:{' '}
                              {skynityConfig.withdrawalFeeFraction * 100}%, min.{' '}
                              {skynityConfig.withdrawalFeeMinSdt} SDT
                            </Tooltip>
                          }
                        >
                          <i
                            className="far fa-question-circle ms-1"
                            onClick={(event): void => event.stopPropagation()}
                          ></i>
                        </OverlayTrigger>
                      </small>
                    </div>
                  )}

                  <ButtonWithSpinner
                    text="Request withdrawal"
                    classes="btn btn-primary section-action-button"
                    onClick={(): void => onRequestWithdraw(withdrawValue)}
                    disabled={withdrawButtonDisabled}
                    loading={loading}
                  />
                </div>
                {withdrawValueBN.isGreaterThan(inAccountBn) && (
                  <Message
                    messageType={MessageType.Error}
                    descriptionText={
                      MessageText.WithdrawValueGreaterThanAvailable
                    }
                  />
                )}
              </>
            ) : (
              <Message
                messageType={MessageType.Primary}
                descriptionText="You currently don't have any SDT available for withdrawal in this account. If you have collected any SDT within the game, please make sure to teleport them first within the game to your game account through a portal."
              />
            )}
          </div>
        </div>
      )}
    </Tab.Pane>
  );
};
