import bondedImage from 'assets/svg/bonded.svg';
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, DataItem } from 'components';
import dayjs from 'dayjs';
import { DateFormat, roundNumber } from 'helpers';
import {
  MainTokenSymbol,
  StakingPoolItemBondedTokensData,
  StakingPoolItemStakingData,
} from 'models';
import React from 'react';

interface BondedTokensProps {
  loading: boolean;
  staking: StakingPoolItemStakingData;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  tokenSymbol: MainTokenSymbol;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
}

// TODO: Unify with locked (interfaces, classes)
export const BondedTokens: React.FC<BondedTokensProps> = ({
  loading,
  staking,
  bondedTokensData,
  tokenSymbol,
  onWithdraw,
}) => {
  const unbondingTableItems = bondedTokensData.map(
    (unbondingTableItem: StakingPoolItemBondedTokensData, index: number) => {
      // TODO
      // EVM
      // const timestamp = await web3.eth.getBlock(await web3.eth.getBlockNumber()))
      //               .timestamp

      const isAvailableForWithdrawal =
        +unbondingTableItem.timestamp <= dayjs().unix();

      return (
        <div
          className={`bonded-tokens-table-item ${
            isAvailableForWithdrawal ? 'ready' : ''
          }`}
          key={`bonded-tokens-table-item-${index}`}
        >
          <div className="time">
            {isAvailableForWithdrawal
              ? 'Available for withdrawal'
              : dayjs(+unbondingTableItem.timestamp * 1000).format(
                  DateFormat.DateWithTime
                )}
          </div>
          <div className="amount">
            {roundNumber(unbondingTableItem.amount)} {tokenSymbol}
          </div>
        </div>
      );
    }
  );

  const availableForWithdrawalBN: BigNumber = new BigNumber(
    staking.availableForWithdrawal
  );
  const totalInUnbondingBN: BigNumber = new BigNumber(staking.totalInUnbonding);
  const bondedBN: BigNumber = totalInUnbondingBN.minus(
    availableForWithdrawalBN
  );

  return (
    <div className="bonded-tokens">
      <div className="bonded-tokens-header">
        <img className="bonded-tokens-image" src={bondedImage} />
        <div className="bonded-tokens-header-summary">
          <DataItem
            label="Bonded tokens"
            data={staking.totalInUnbonding}
            iconClasses="fas fa-hourglass"
            symbol={tokenSymbol}
            classes="align-center justify-start"
          />
          <DataItem
            label="Available for withdrawal"
            data={staking.availableForWithdrawal}
            iconClasses="fas fa-check"
            symbol={tokenSymbol}
            classes="align-center justify-start"
          />
        </div>
      </div>

      <div className="bonded-tokens-table">
        <div className="bonded-tokens-table-header">
          <div className="time">
            Bonded till
            <span className="time-amount-separator"> / </span>
          </div>
          <div className="amount">Bonded amount</div>
        </div>
        {unbondingTableItems}
        <div className="bonded-tokens-buttons">
          <div className="bonded-tokens-button-wrapper">
            {/* TODO: Check */}
            {/* <div
              className={`${
                availableForWithdrawalBN.isEqualTo(0) ? 'disabled' : ''
              }`}
            > */}
            <ButtonWithSpinner
              classes={`btn btn-secondary  relative ${
                availableForWithdrawalBN.isEqualTo(0) ? 'disabled' : ''
              }`}
              onClick={onWithdraw}
              disabled={availableForWithdrawalBN.isEqualTo(0)}
              loading={loading}
            >
              Withdraw
            </ButtonWithSpinner>
            {/* </div> */}
            <div className="fee-text">
              <span className="primary">
                {roundNumber(staking.availableForWithdrawal)} {tokenSymbol}
              </span>
              <span className="ml-5">- no fee</span>
            </div>
          </div>

          <div className="bonded-tokens-button-wrapper">
            <ButtonWithSpinner
              classes="btn btn-primary relative"
              onClick={(event): void => onWithdraw(event, true)}
              disabled={
                staking.instantWithdrawalPenalty && bondedBN.isEqualTo(0)
              }
              loading={loading}
            >
              Instant Withdraw
            </ButtonWithSpinner>
            <div className="fee-text">
              {staking.instantWithdrawalPenalty && bondedBN.isGreaterThan(0) ? (
                <>
                  <span className="primary">
                    {roundNumber(
                      totalInUnbondingBN
                        .minus(
                          bondedBN
                            .times(staking.instantWithdrawalPenalty)
                            .div(100)
                        )
                        .toString(10)
                    )}{' '}
                    {tokenSymbol}
                  </span>
                  <span className="ml-4">
                    - incl. {staking.instantWithdrawalPenalty}% penalty on{' '}
                    {roundNumber(bondedBN.toString(10))}
                  </span>
                </>
              ) : // eslint-disable-next-line unicorn/no-nested-ternary
              staking.instantWithdrawalPenalty ? (
                <span>All tokens already unbonded</span>
              ) : (
                <span>Not available in this pool</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
