import claimImage from 'assets/svg/claim.svg';
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner } from 'components';
import React from 'react';
import { Tab } from 'react-bootstrap';
import { StakingPoolItemStakingData } from '../../../models';

interface StakingPoolItemClaimTabContentProps {
  loading: boolean;
  staking: StakingPoolItemStakingData;
  onClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const StakingPoolItemClaimTabContent: React.FC<
  StakingPoolItemClaimTabContentProps
> = ({ loading, staking, onClaim }) => {
  const canClaim = new BigNumber(staking.rewardTokens).isGreaterThan(0);
  const claimSectionClasses = `claim-section ${
    canClaim ? 'claimable' : 'not-claimable'
  }`;
  const claimSectionHeader = canClaim
    ? 'Well Done'
    : 'You do not have any rewards';
  const claimSectionDetails = (
    <>
      <div className="text">
        {canClaim
          ? 'You can claim your reward'
          : // eslint-disable-next-line unicorn/no-nested-ternary
          +staking.stakedTokens > 0
          ? 'Please come back later'
          : 'Stake some tokens to start earning rewards'}
      </div>
      <div>
        {canClaim && (
          <span className="primary">{staking.rewardTokens} SDT</span>
        )}
      </div>
    </>
  );

  return (
    <Tab.Pane eventKey="claim" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className={claimSectionClasses}>
          <div>
            <div className="claim-section-info">
              <img className="bonded-tokens-image" src={claimImage} />

              <div className="claim-section-info-header">
                {claimSectionHeader}
              </div>
              <div className="claim-section-info-details mb-16">
                {claimSectionDetails}
              </div>
            </div>

            <ButtonWithSpinner
              text="Claim"
              classes="btn btn-primary section-action-button"
              onClick={onClaim}
              disabled={!canClaim}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Tab.Pane>
  );
};
