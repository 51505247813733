import {
  LPPairsContracts,
  LPsData,
  LPTokensPrices,
  LPTokenSymbol,
  TokenPrices,
  TokensData,
} from 'models';
import Web3 from 'web3';

import { createAction } from '@reduxjs/toolkit';

export const getLPPairsContracts = createAction<{
  networkId: string;
  web3: Web3;
}>('GET_LP_PAIRS_CONTRACTS');

export const getLPPairsContractsSuccess = createAction<LPPairsContracts>(
  'GET_LP_PAIRS_CONTRACTS_SUCCESS'
);

export const getLPPairsContractsFailure = createAction(
  'GET_LP_PAIRS_CONTRACTS_FAILURE'
);

export const getLPsData = createAction<{
  tokensData: TokensData;
  LPPairsContracts: LPPairsContracts;
}>('GET_LP_DATA');

export const getLPsDataSuccess = createAction<
  Partial<Record<LPTokenSymbol, { tokensInLPPair: string; totalLP: string }>>
>('GET_LP_DATA_SUCCESS');

export const getLPDataFailure = createAction('GET_LP_DATA_FAILURE');

export const getLPTokensPrices = createAction<{
  tokensPrices: TokenPrices;
  LPsData: LPsData;
}>('GET_LP_TOKEN_PRICE');

export const getLPTokensPricesSuccess = createAction<LPTokensPrices>(
  'GET_LP_TOKEN_PRICE_SUCCESS'
);

export const getLPTokensPricesFailure = createAction(
  'GET_LP_TOKEN_PRICE_FAILURE'
);

export const resetLPState = createAction('RESET_LP_STATE');
