import { AppChain } from 'helpers';

import { isChain } from './connection.helper';

export const applyMask = (text: string): string =>
  `${text.slice(0, 6)}**${text.slice(-4)}`;

export const roundNumber = (value: string, precision = 6): string => {
  if (isChain(AppChain.Bsc) && precision == 6) {
    precision = 8;
  }

  const pointIndex = value.lastIndexOf('.');

  return pointIndex > 0 ? value.slice(0, pointIndex + precision + 1) : value;
};

export const isCorrectValue = (value: string, decimals: number): boolean => {
  let decimalsCount = 0;

  if (value?.indexOf('.') > -1) {
    decimalsCount = value.slice(value.indexOf('.') + 1, value.length).length;
  }

  return decimalsCount <= decimals;
};

export const asyncSleep = (milliseconds: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const copyToClipboard = (value: string): void => {
  navigator.clipboard.writeText(value);
};

export const parseCurrency = (
  value: string,
  maximumFractionDigits?: number
): string =>
  Number.parseFloat(value).toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
    ...(maximumFractionDigits && { maximumFractionDigits }),
  });

export enum CGStableCoins {
  BUSD = 'binance-usd',
  USDT = 'tether',
  USDC = 'usd-coin',
  DAI = 'dai',
}

export const humanizeDuration = (seconds: number): string => {
  const levels = [
    [Math.floor(seconds / 31536000), 'years'],
    [Math.floor((seconds % 31536000) / 86400), 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
    [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
  ];
  let returnText = '';

  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0) continue;
    returnText += ` ${levels[i][0]} ${
      levels[i][0] === 1
        ? (levels[i][1] as string).slice(
            0,
            Math.max(0, (levels[i][1] as string).length - 1)
          )
        : levels[i][1]
    }`;
  }
  return returnText.trim();
};
