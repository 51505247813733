import React, { PropsWithChildren, ReactNode } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import { TruncatedNumber } from './TruncatedNumber';

interface DataItemProps {
  label: ReactNode;
  symbol?: string;
  data?: string;
  classes?: string;
  iconClasses?: string;
  tooltipHint?: ReactNode;
  tooltipPlacement?: Placement;
  tooltipClasses?: string;
  tooltipStyle?: React.CSSProperties;
}

export const DataItem: React.FC<PropsWithChildren<DataItemProps>> = ({
  label,
  symbol,
  data,
  classes,
  iconClasses,
  tooltipHint,
  tooltipPlacement,
  tooltipClasses,
  children,
}) => {
  const spinner = !children && (data == null || data == undefined) && (
    <Spinner animation="border" role="status" variant="light" size="sm" />
  );

  return (
    <div className={`data-item ${classes ?? 'align-center'}`}>
      {iconClasses ? (
        <div className="data-item-icon">
          <i className={iconClasses} />
        </div>
      ) : (
        ''
      )}
      <div className="data-item-content">
        <div className="data-item-header">{label}</div>
        <div className="data-item-data">
          {spinner}
          {data && (
            <>
              <TruncatedNumber value={data} /> {symbol}
            </>
          )}
          {!data && children}
          {tooltipHint && (
            <OverlayTrigger
              overlay={
                <Tooltip className={tooltipClasses}>{tooltipHint}</Tooltip>
              }
              placement={tooltipPlacement ?? 'top'}
            >
              <i
                className="far fa-question-circle apy-question-icon ms-1"
                onClick={(event): void => event.stopPropagation()}
              ></i>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  );
};
