import BigNumber from 'bignumber.js';
import { ButtonWithSpinner } from 'components/ButtonWithSpinner';
import { PercentageButtons } from 'components/PercentageButtons';
import {
  isCorrectValue,
  MessageText,
  MessageType,
  numbersOnly,
  skynityConfig,
} from 'helpers';
import {
  DepositSource,
  TokenData,
  VestingPoolData,
  VestingPoolVestingData,
} from 'models';
import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';

import { GameAccountItemDepositTabContentSourceSelector } from './GameAccountItemDepositTabContentSourceSelector';
import { Message } from 'components/Message';

interface GameAccountItemDepositTabContentProps {
  connectedAddress: string;
  loading: boolean;
  waitingForDeposit: boolean;
  depositAllowance: string;
  inWallet: string;
  tokenData: TokenData;
  vestingPoolsData: VestingPoolData[];
  selectedVestingPoolData: VestingPoolData;
  selectedVestingPoolVestingData: VestingPoolVestingData;
  pendingDeposit: string;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSelectVestingPool: (vestingPoolData: VestingPoolData) => void;
  onDepositFromWallet: (depositValue: string) => void;
  onDepositFromVesting: (depositValue: string) => void;
}

export const GameAccountItemDepositTabContent: React.FC<
  GameAccountItemDepositTabContentProps
  // eslint-disable-next-line complexity
> = ({
  connectedAddress,
  loading,
  waitingForDeposit,
  depositAllowance,
  inWallet,
  tokenData,
  vestingPoolsData,
  selectedVestingPoolData,
  selectedVestingPoolVestingData,
  pendingDeposit,
  onApprove,
  onSelectVestingPool,
  onDepositFromWallet,
  onDepositFromVesting,
}) => {
  const [depositSource, setDepositSource] = useState<DepositSource>('wallet');
  const [depositValue, setDepositValue] = useState<string>('');
  const [availableAmount, setAvailableAmount] = useState<string>('');
  const depositValueBN = new BigNumber(depositValue);
  const depositAllowanceBN = new BigNumber(depositAllowance);
  const availableSdtInWalletBN = new BigNumber(inWallet);

  useEffect(() => {
    if (!loading) {
      setDepositValue('0');
    }
  }, [loading]);

  useEffect(() => {
    if (depositSource === 'wallet') {
      setAvailableAmount(availableSdtInWalletBN.toString(10));
    } else {
      setAvailableAmount(
        BigNumber(selectedVestingPoolVestingData?.amountLeft).toString(10)
      );
    }
  }, [depositSource, selectedVestingPoolVestingData]);

  const inputDisabled = (): boolean => {
    if (loading) {
      return true;
    }

    if (depositSource === 'wallet') {
      return availableSdtInWalletBN.isEqualTo(0);
    } else if (depositSource === 'vesting') {
      return (
        !selectedVestingPoolVestingData ||
        +selectedVestingPoolVestingData?.amountLeft === 0
      );
    }

    return true;
  };

  const depositButtonDisabled = (): boolean => {
    let isGreaterThanAvailable;

    if (depositSource === 'wallet') {
      isGreaterThanAvailable = depositValueBN.isGreaterThan(
        availableSdtInWalletBN
      );
    } else if (depositSource === 'vesting') {
      isGreaterThanAvailable = depositValueBN.isGreaterThan(
        new BigNumber(+selectedVestingPoolVestingData?.amountLeft)
      );
    }

    return (
      inputDisabled() ||
      +depositValue == 0 ||
      +depositValue < skynityConfig.minimumDepositSdt ||
      isGreaterThanAvailable
    );
  };

  const onApplyPercentage = (percentage: number): void => {
    const value =
      depositSource === 'wallet'
        ? availableSdtInWalletBN
        : new BigNumber(selectedVestingPoolVestingData.amountLeft);

    const roundedNumber = BigNumber(
      value.times(percentage).div(100).toFixed(tokenData.decimals)
    ).toString(10);

    setDepositValue(roundedNumber);
  };

  const onSetDepositSource = (depositSource: DepositSource): void => {
    setDepositValue('0');
    setDepositSource(depositSource);
    if (vestingPoolsData.length == 1) {
      onSelectVestingPool(vestingPoolsData[0]);
    } else {
      onSelectVestingPool(undefined);
    }
  };

  const onDepositValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokenData.decimals)) {
      setDepositValue(value);
    }
  };

  const onDepositButtonClick = (): void => {
    if (depositSource === 'wallet') {
      onDepositFromWallet(depositValue);
    } else if (depositSource === 'vesting') {
      onDepositFromVesting(depositValue);
    }
  };

  const canDepositFromWallet =
    depositSource === 'wallet' &&
    depositAllowanceBN.isGreaterThan(0) &&
    depositAllowanceBN.isGreaterThan(+depositValue);
  const canDeposit = depositSource === 'vesting' || canDepositFromWallet;
  const button = canDeposit ? (
    <ButtonWithSpinner
      text="Deposit"
      classes="btn btn-primary section-action-button"
      disabled={depositButtonDisabled()}
      onClick={(): void => onDepositButtonClick()}
      loading={loading}
    />
  ) : (
    <ButtonWithSpinner
      text="Approve"
      classes="btn btn-primary section-action-button"
      onClick={onApprove}
      loading={loading}
    />
  );

  return (
    <Tab.Pane eventKey="deposit" unmountOnExit={true} mountOnEnter={true}>
      {+pendingDeposit ? (
        <Message
          messageType={
            waitingForDeposit ? MessageType.Primary : MessageType.Error
          }
        >
          <div className="d-flex align-items-center gap-3">
            {waitingForDeposit && (
              <div>
                <Spinner></Spinner>
              </div>
            )}
            <div>
              {waitingForDeposit
                ? `Please wait for the current deposit to process on the server.`
                : `You have another ongoing deposit from your wallet/vesting on this
              server - please refresh the page after a couple of minutes.`}
            </div>
          </div>
        </Message>
      ) : (
        <div className="tab-content">
          <div className="unstake-section">
            <div className="unstake-section-description">
              Deposit your SkyDust (SDT) tokens into the game. You can use
              tokens available in your wallet or you can transfer tokens
              allocated to you in vesting (only if applicable).
            </div>
            <div className="unstake-section-description">
              The deposited tokens will be converted into the rSDT (raw SkyDust)
              in-game currency at a 6:1 ratio (you will get 6 rSDT for every 1
              deposited SDT).
            </div>
            <div className="unstake-section-actions">
              <GameAccountItemDepositTabContentSourceSelector
                connectedAddress={connectedAddress}
                tokenData={tokenData}
                depositSource={depositSource}
                loading={loading}
                vestingPoolsData={vestingPoolsData}
                selectedVestingPoolData={selectedVestingPoolData}
                onSelectVestingPool={(vestingPoolData: VestingPoolData): void =>
                  onSelectVestingPool(vestingPoolData)
                }
                onSetDepositSource={(depositSource: DepositSource): void =>
                  onSetDepositSource(depositSource)
                }
              />

              {canDepositFromWallet || selectedVestingPoolData ? (
                <>
                  <div className="text-end">
                    <small>
                      {skynityConfig.minimumDepositSdt &&
                        `min. ${skynityConfig.minimumDepositSdt} SDT, `}
                      max: {availableAmount} SDT
                    </small>
                  </div>
                  <input
                    className="s-input"
                    placeholder="0.00"
                    onChange={onDepositValueChange}
                    value={depositValue}
                    onKeyPress={numbersOnly}
                    disabled={inputDisabled()}
                  />
                  <PercentageButtons
                    disabled={inputDisabled()}
                    applyPercentage={(percentage: number): void =>
                      onApplyPercentage(percentage)
                    }
                  />
                </>
              ) : (
                ''
              )}

              {depositSource === 'wallet' && !canDepositFromWallet ? (
                <div className="mb-16">{MessageText.Approve}</div>
              ) : (
                ''
              )}

              {button}
            </div>

            {/* TODO */}
            {/* {withdrawValueBN.isGreaterThan(totalTokensInGameBn) && (
            <Message
              messageType={MessageType.Error}
              descriptionText={MessageText.WithdrawValueGreaterThanAvailable}
            />
          )} */}
          </div>
        </div>
      )}
    </Tab.Pane>
  );
};
