export enum StakingPoolItemType {
  Locked = 'Locked',
  Bonded = 'Bonded',
}
import { Contract } from 'web3-eth-contract';

import { TokenSymbol } from './token.model';

export enum StakingPoolType {
  LP = 'LP Staking',
  SA = 'Single Asset Staking',
  SC = 'Stable coin',
}

export enum StakingPoolName {
  LP = 'LP Staking',
  SA = 'Single Asset Staking',
}

export enum StakingPoolItemName {
  Liquid = 'Liquid',
  ThreeMonths = '3 Months Lock',
  SixMonths = '6 Months Lock',
  TwelveMonths = '12 Months Lock',
}

export interface StakingPoolItemBondedTokensData {
  amount: string;
  timestamp: string;
}

export interface StakingPoolItemStakingData {
  stakingAllowance: string;
  rewardTokens: string;
  stakedTokens: string;
  totalValueLocked: string;
  availableForWithdrawal: string;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  totalInUnbonding: string;
  lockPeriod?: string;
  unbondingPeriod?: string;
  unstakingFee: string;
  instantWithdrawalPenalty?: string;
  rewardRate?: string;
  maxLockedStakesPerAccount?: string;
  maxUnbondingsPerAccount?: string;
  maxStake?: string;
  moveBondAvailable?: boolean;
  moveBondDestinationStakingPoolItems?: StakingPoolItemData[];
}

export interface StakingConfig {
  pools: StakingPoolConfig[];
}

export type StakingPoolItemLockPeriod = 3 | 6 | 12;

export interface StakingPoolItemConfig {
  poolItemName: StakingPoolItemName | string;
  poolItemtype: StakingPoolItemType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stakingContractJSON: any;
  hasWhitelist?: boolean;
}

export interface StakingPoolItemData {
  poolItemName: StakingPoolItemName | string;
  poolItemtype: StakingPoolItemType;
  stakingPoolItemContract: Contract;
  contractAddress?: string;
  addressCanStake?: boolean;
}

export interface StakingPoolConfig {
  poolType: StakingPoolType;
  poolName: StakingPoolName;
  poolDescription: string;
  poolStakingTokenSymbol: TokenSymbol;
  poolItems: StakingPoolItemConfig[];
}

export interface StakingPoolData {
  poolType: StakingPoolType;
  poolName: StakingPoolName;
  poolDescription: string;
  poolStakingTokenSymbol: TokenSymbol;
  poolItems: StakingPoolItemData[];
}

export interface StakingPoolItemHeaderData {
  apy: string;
  apr: string;
  tvlDolars: string;
}
