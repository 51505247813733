import lockedImage from 'assets/svg/locked.svg';
import { DataItem } from 'components';
import dayjs from 'dayjs';
import { DateFormat, roundNumber } from 'helpers';
import {
  MainTokenSymbol,
  StakingPoolItemBondedTokensData,
  StakingPoolItemStakingData,
} from 'models';
import React from 'react';

interface LockedTokensProps {
  staking: StakingPoolItemStakingData;
  lockedTokensData: StakingPoolItemBondedTokensData[];
  tokenSymbol: MainTokenSymbol;
}

// TODO: Unify with bonded (interfaces, classes)
export const LockedTokens: React.FC<LockedTokensProps> = ({
  staking,
  lockedTokensData,
  tokenSymbol,
}) => {
  const unlockTableItems = lockedTokensData.map(
    (unlockTableItem: StakingPoolItemBondedTokensData, index: number) => {
      const isAvailableForWithdrawal =
        +unlockTableItem.timestamp <= dayjs().unix();

      return (
        <div
          className={`bonded-tokens-table-item ${
            isAvailableForWithdrawal ? 'ready' : ''
          }`}
          key={`bonded-tokens-table-item-${index}`}
        >
          <div className="time">
            {isAvailableForWithdrawal
              ? 'Available for withdrawal'
              : dayjs(+unlockTableItem.timestamp * 1000).format(
                  DateFormat.DateWithTime
                )}
          </div>
          <div className="amount">
            {roundNumber(unlockTableItem.amount)} {tokenSymbol}
          </div>
        </div>
      );
    }
  );

  return (
    <div className="bonded-tokens">
      <div className="bonded-tokens-header">
        <img className="bonded-tokens-image" src={lockedImage} />
        {/* <div className="bonded-tokens-message"> */}
        {/* <Message headerText="Lock details"> */}
        {/* <DataItem
              label="Available for withdrawal"
              data={staking.availableForWithdrawal}
              symbol={tokenSymbol}
              classes="justify-between mt-8"
            /> */}
        {/* </Message> */}
        {/* </div> */}
        <div>
          <DataItem
            label="Available for withdrawal"
            data={staking.availableForWithdrawal}
            symbol={tokenSymbol}
            classes="justify-between mt-8"
          />
        </div>
      </div>
      <div className="bonded-tokens-table">
        <div className="bonded-tokens-table-header">
          <div className="time">
            Locked till<span className="time-amount-separator"> / </span>
          </div>
          <div className="amount">Locked amount</div>
        </div>
        {unlockTableItems}
      </div>
    </div>
  );
};
