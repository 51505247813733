import BigNumber from 'bignumber.js';
import {
  getPoolHeaderData,
  humanizeDuration,
  isProduction,
  parseCurrency,
} from 'helpers';
import {
  LPsData,
  LPTokenSymbol,
  StakingPoolItemData,
  StakingPoolItemHeaderData,
  StakingPoolItemStakingData,
  StakingPoolItemType,
  StakingPoolType,
  TokenData,
  TokenPrices,
} from 'models';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

interface StakingPoolItemHeaderProps {
  stakingPoolItemData: StakingPoolItemData;
  staking: StakingPoolItemStakingData;
  tokensPrices: TokenPrices;
  stableTokenPrice: string;
  LPsData: LPsData;
  stakingPoolType: StakingPoolType;
  stakingTokenData: TokenData;
}

export const StakingPoolItemHeader: React.FC<StakingPoolItemHeaderProps> = ({
  stakingPoolItemData,
  staking,
  tokensPrices,
  stableTokenPrice,
  LPsData,
  stakingPoolType,
  stakingTokenData,
}) => {
  const [poolHeaderData, setPoolHeaderData] =
    useState<StakingPoolItemHeaderData>();

  useEffect(() => {
    if (tokensPrices && stableTokenPrice && LPsData && staking) {
      // TODO: To be fixed - symbol
      const poolHeaderData: StakingPoolItemHeaderData = getPoolHeaderData(
        tokensPrices.SDT,
        stableTokenPrice,
        LPsData[stakingTokenData.symbol as LPTokenSymbol],
        staking.totalValueLocked,
        staking.rewardRate,
        stakingPoolType
      );

      setPoolHeaderData({
        tvlDolars: new BigNumber(poolHeaderData.tvlDolars).toFixed(2),
        apr: new BigNumber(poolHeaderData.apr).toFixed(2),
        apy: new BigNumber(poolHeaderData.apy).toFixed(2),
      });
    }
  }, [tokensPrices, stableTokenPrice, LPsData, staking]);

  return (
    <div className="staking-pool-item-header">
      {poolHeaderData ? (
        <>
          <div className="name text-capitalize">
            {stakingPoolItemData.poolItemtype === StakingPoolItemType.Locked
              ? stakingPoolItemData.poolItemName.replace(
                  '#DURATION#',
                  humanizeDuration(+staking.lockPeriod)
                )
              : stakingPoolItemData.poolItemName}
          </div>
          <div className="apy">
            <div>
              <i className="fa fa-chart-line me-2" />
              est. APY:{' '}
              {+poolHeaderData.apy < 10000 ? (
                `${poolHeaderData.apy}%`
              ) : (
                <>&infin;</>
              )}
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Annual Percentage Yield assuming monthly compounding
                  </Tooltip>
                }
              >
                <i
                  className="far fa-question-circle apy-question-icon"
                  onClick={(event): void => event.stopPropagation()}
                ></i>
              </OverlayTrigger>
            </div>
            {+staking.maxStake ? (
              <div className="apy-limit">
                limit {staking.maxStake} {stakingTokenData.symbol}*
              </div>
            ) : (
              ''
            )}
            {!isProduction() && (
              <small>[DEV_ONLY: APR {poolHeaderData.apr}%]</small>
            )}
          </div>
          <div className="tvl">
            <i className="fa fa-landmark me-2" />
            TVL: {parseCurrency(poolHeaderData.tvlDolars)}
          </div>
        </>
      ) : (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status" variant="light" size="sm" />
        </div>
      )}
    </div>
  );
};
