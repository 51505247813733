/* eslint-disable complexity */
import { fromWei } from 'helpers';
import {
  StakingPoolItemBondedTokensData,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import { Contract } from 'web3-eth-contract';

export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export enum StakingPoolActions {
  Stake = 'stake',
  Unstake = 'unstake',
  Withdraw = 'withdraw',
}

export const getStakingPoolItemData = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingPoolItemsData: StakingPoolItemData[],
  stakingTokenData: TokenData,
  connectedAddress: string
): Promise<StakingPoolItemStakingData> => {
  const decimals = stakingTokenData.decimals;
  const rewardTokenDecimals = 18; // SDT decimals
  const stakingAllowance = await stakingTokenData.tokenContract.methods
    .allowance(
      connectedAddress,
      stakingPoolItemData.stakingPoolItemContract.options.address
    )
    .call();
  const poolInfo: {
    stakingToken: string;
    rewardsToken: string;
    rewardRate: string;
    periodFinish: string;
    unstakingFee: string;
    unbondingPeriod: string;
    maxUnbondingsPerAccount: string;
    instantWithdrawalPenalty: string;
    // BELOW ONLY FOR LOCKED
    lockPeriod: string;
    maxStakesPerAccount: string;
    stakingLimit?: string;
  } = await stakingPoolItemData.stakingPoolItemContract.methods
    .getPoolInfo()
    .call();
  const currentStats: {
    poolTotalSupply: string;
    poolRewardsBalance: string;
    poolRewardRate: string;
    stakedAmount: string;
    rewardsEarned: string;
    totalInUnbonding: string;
    availableForWithdrawal: string;
    unbondings: { timestamp: string; amount: string }[];
    // BELOW ONLY FOR LOCKED
    stakes: { timestamp: string; amount: string }[];
  } = await stakingPoolItemData.stakingPoolItemContract.methods
    .getCurrentStats(connectedAddress)
    .call();
  let moveBondDestinationAddresses: string[];
  const instantWithdrawalPenalty = (
    +poolInfo.instantWithdrawalPenalty / 100
  ).toString();
  const unbondingPeriod = poolInfo.unbondingPeriod;

  try {
    moveBondDestinationAddresses =
      await stakingPoolItemData.stakingPoolItemContract.methods
        .getMoveStakeGroup()
        .call();
  } catch {
    moveBondDestinationAddresses = undefined;
  }

  let bondedTokensData: StakingPoolItemBondedTokensData[] = [];

  if (unbondingPeriod === undefined) {
    bondedTokensData = currentStats.stakes.map(
      ({ amount, timestamp }: StakingPoolItemBondedTokensData) => ({
        amount: fromWei(amount, decimals),
        timestamp: (+timestamp + +poolInfo.lockPeriod).toString(),
      })
    );
  } else {
    bondedTokensData = currentStats.unbondings.map(
      ({ amount, timestamp }: StakingPoolItemBondedTokensData) => ({
        amount: fromWei(amount, decimals),
        timestamp: (+timestamp + +unbondingPeriod).toString(),
      })
    );
  }

  moveBondDestinationAddresses = moveBondDestinationAddresses?.filter(
    (contractAddress: string) =>
      contractAddress !==
      stakingPoolItemData?.stakingPoolItemContract?.options?.address
  );
  const moveBondDestinationStakingPoolItems =
    moveBondDestinationAddresses?.reduce(
      (acc: StakingPoolItemData[], contractAddress: string) => {
        const applicablePoolItemData = stakingPoolItemsData.find(
          (poolItemData: StakingPoolItemData) =>
            poolItemData?.stakingPoolItemContract?.options?.address ===
              contractAddress && poolItemData.addressCanStake
        );

        if (applicablePoolItemData) {
          acc.push(applicablePoolItemData);
        }

        return acc;
      },
      []
    );
  const moveBondAvailable =
    (!!+currentStats.stakedAmount || !!+currentStats.totalInUnbonding) &&
    !!moveBondDestinationStakingPoolItems?.length;

  return {
    ...(poolInfo.lockPeriod && { lockPeriod: poolInfo.lockPeriod }),
    ...(unbondingPeriod && { unbondingPeriod }),
    stakingAllowance: fromWei(stakingAllowance, decimals),
    rewardTokens: fromWei(currentStats.rewardsEarned, rewardTokenDecimals),
    stakedTokens: fromWei(currentStats.stakedAmount, decimals),
    totalValueLocked: fromWei(currentStats.poolTotalSupply, decimals),
    availableForWithdrawal: fromWei(
      currentStats.availableForWithdrawal,
      decimals
    ),
    bondedTokensData,
    totalInUnbonding: fromWei(currentStats.totalInUnbonding, decimals),
    unstakingFee: (+poolInfo.unstakingFee / 10000).toString(),
    ...(instantWithdrawalPenalty && { instantWithdrawalPenalty }),
    rewardRate: fromWei(currentStats.poolRewardRate, rewardTokenDecimals),
    ...(poolInfo.maxStakesPerAccount && {
      maxLockedStakesPerAccount: poolInfo.maxStakesPerAccount,
    }),
    ...(poolInfo.maxUnbondingsPerAccount && {
      maxUnbondingsPerAccount: poolInfo.maxUnbondingsPerAccount,
    }),
    ...(poolInfo.stakingLimit && {
      maxStake: fromWei(poolInfo.stakingLimit, decimals),
    }),
    moveBondAvailable,
    ...(moveBondDestinationStakingPoolItems?.length && {
      moveBondDestinationStakingPoolItems,
    }),
  };
};

export const canStake = async (
  connectedAddress: string,
  contract: Contract
): Promise<boolean> => {
  try {
    const canStake = await contract.methods
      .isWhitelisted(connectedAddress)
      .call();
    return canStake;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);

    return false;
  }
};
