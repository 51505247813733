import { PayloadAction } from '@reduxjs/toolkit';

interface VestingPoolState {
  loading: boolean;
  errorMessage: string;
  vesting: {
    amountAllocated: string;
    amountClaimable: string;
    amountClaimed: string;
    amountClaimedToBridge: string;
    amountClaimedTotal: string;
    amountLeft: string;
  };
}

export const initialVestingPoolState: VestingPoolState = {
  loading: null,
  errorMessage: '',
  vesting: null,
};

export enum VestingPoolActions {
  SetLoading = 'SET_LOADING',
  SetErrorMessage = 'SET_ERROR_MESSAGE',
  SetVesting = 'SET_VESTING',
}

export const vestingPoolReducer = (
  state: VestingPoolState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: PayloadAction<any, VestingPoolActions>
): VestingPoolState => {
  switch (action.type) {
    case VestingPoolActions.SetLoading: {
      return { ...state, loading: action.payload };
    }
    case VestingPoolActions.SetErrorMessage: {
      return { ...state, errorMessage: action.payload };
    }
    case VestingPoolActions.SetVesting: {
      return {
        ...state,
        vesting: { ...action.payload },
      };
    }
    default: {
      state;
    }
  }
};
