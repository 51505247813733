/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import {
  humanizeDuration,
  MessageText,
  MessageType,
  numbersOnly,
} from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemStakeTabContentProps {
  loading: boolean;
  balance: string;
  stakingValue: string;
  staking: StakingPoolItemStakingData;
  stakingPoolItemData: StakingPoolItemData;
  stakingTokenData: TokenData;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number, value: string) => void;
}

export const StakingPoolItemStakeTabContent: React.FC<
  StakingPoolItemStakeTabContentProps
> = ({
  loading,
  balance,
  stakingValue,
  staking,
  stakingPoolItemData,
  stakingTokenData,
  onApprove,
  onStake,
  onStakeValueChange,
  onApplyPercentage,
}) => {
  const stakingValueBN = new BigNumber(stakingValue);
  const balanceBN = new BigNumber(balance);
  const stakingAllowanceBN = new BigNumber(staking.stakingAllowance);
  const lockedStakesExceeded =
    staking.lockPeriod != undefined &&
    staking.bondedTokensData?.length >= +staking.maxLockedStakesPerAccount;
  const maxStakeReached =
    +staking?.maxStake &&
    new BigNumber(staking.maxStake)
      .minus(new BigNumber(staking.stakedTokens).plus(stakingValueBN))
      .isLessThan(0);
  const stakeButtonDisabled =
    +stakingValue == 0 ||
    stakingValueBN.isGreaterThan(balanceBN) ||
    lockedStakesExceeded ||
    maxStakeReached;

  const button = stakingValueBN.isGreaterThan(stakingAllowanceBN) ? (
    <ButtonWithSpinner
      text="Approve"
      classes="btn btn-primary section-action-button"
      onClick={onApprove}
      loading={loading}
    />
  ) : (
    <ButtonWithSpinner
      text="Stake"
      classes="btn btn-primary section-action-button"
      onClick={onStake}
      disabled={stakeButtonDisabled}
      loading={loading}
    />
  );

  return (
    <Tab.Pane eventKey="stake" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="stake-section">
          <div className="stake-section-description">
            {staking.unbondingPeriod === undefined ? (
              <span>
                After staking the tokens will be locked for a period of{' '}
                <strong>{humanizeDuration(+staking.lockPeriod)}</strong>. By
                staking in this pool you acknowledge that you will not be able
                to withdraw the staked tokens by any means before this locking
                period elapses.
              </span>
            ) : (
              <div>
                After staking the tokens can be unstaked at any time, however
                after unstaking they cannot be withdrawn for an unbonding period
                of <strong>{humanizeDuration(+staking.unbondingPeriod)}</strong>
                , during which the rewards are no longer accrued. Withdrawing
                the unstaked tokens before the unbonding period ends will still
                be possible using the Instant Withdraw option, however with a
                penalty of <strong>{staking.instantWithdrawalPenalty}%</strong>.
              </div>
            )}
          </div>
          {+staking.stakingAllowance == 0 && (
            <div className="approve-section">
              <div className="mb-16">
                <Message
                  messageType={MessageType.Primary}
                  descriptionText={MessageText.Approve}
                />
              </div>
              <ButtonWithSpinner
                text="Approve"
                classes="btn btn-primary section-action-button"
                onClick={onApprove}
                disabled={loading}
                loading={loading}
              />
            </div>
          )}
          {+staking.stakingAllowance > 0 &&
            !lockedStakesExceeded &&
            !maxStakeReached && (
              <div className="stake-section-actions">
                <input
                  className="s-input"
                  placeholder="0.00"
                  onChange={onStakeValueChange}
                  value={stakingValue}
                  onKeyPress={numbersOnly}
                  disabled={balanceBN.isEqualTo(0)}
                />

                <PercentageButtons
                  applyPercentage={(percentage: number): void =>
                    onApplyPercentage(percentage, balance)
                  }
                  disabled={balanceBN.isEqualTo(0) || loading}
                />

                {button}
              </div>
            )}
        </div>
        {stakingValueBN.isGreaterThan(stakingAllowanceBN) ? (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.ValueGreaterThanApproved}
          />
        ) : (
          ''
        )}
      </div>
      {!+balance && !!+staking.stakingAllowance && (
        <Message
          descriptionText={MessageText.NoTokens}
          messageType={MessageType.Primary}
        />
      )}
      {lockedStakesExceeded && +staking.stakingAllowance > 0 && (
        <Message
          descriptionText={`You cannot have more than ${staking.maxLockedStakesPerAccount} active stakes from one address. Please withdraw some tokens first.`}
          messageType={MessageType.Primary}
        />
      )}
      {maxStakeReached && +staking.stakingAllowance > 0 ? (
        <Message
          descriptionText={`You cannot stake more than ${staking.maxStake} ${stakingTokenData.symbol}`}
          messageType={MessageType.Primary}
        />
      ) : (
        ''
      )}
    </Tab.Pane>
  );
};
