import { DataItem } from 'components';
import { MainTokenSymbol, rSDTTokenSymbol } from 'models';
import React from 'react';

interface GameAccountItemDetailsProps {
  inAccountSdt: string;
  inVesting: string;
  inWallet: string;
  inAccountRsdt: string;
  pendingDeposit: string;
  pendingWithdrawal: string;
}

export const GameAccountItemDetails: React.FC<GameAccountItemDetailsProps> = ({
  inAccountSdt,
  inVesting,
  inWallet,
  inAccountRsdt,
  pendingDeposit,
  pendingWithdrawal,
}) => (
  <div className="staking-pool-item-details justify-content-evenly">
    {/* <DataItem
      label="In wallet"
      data={inWallet}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-wallet'}
    /> */}

    <DataItem
      label="SDT in account"
      data={inAccountSdt}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fas fa-gem'}
    />

    <DataItem
      label="rSDT in game"
      data={inAccountRsdt}
      symbol={rSDTTokenSymbol}
      iconClasses={'fa fa-cubes-stacked'}
    />

    {/* <DataItem label="In game account" iconClasses={'fa fa-cubes-stacked'}>
      <div className="d-flex nowrap flex-wrap" style={{ columnGap: '1rem' }}>
        <div>
          <i className="fa fa-regular fa-gem" /> {roundNumber(inAccountRsdt)}{' '}
          {rSDTTokenSymbol}
        </div>
        <div>
          <i className="fa fa-solid fa-gem" /> {roundNumber(inAccountSdt)}{' '}
          {MainTokenSymbol.SDT}
        </div>
      </div>
    </DataItem> */}

    {/* TODO: consider enabling this - must include check if the pending deposit applies to this account (pendingDeposit.data feild in result from contract) */}
    {/* <DataItem
      label="Pending deposit"
      data={pendingDeposit}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-hourglass'}
    /> */}

    {/* <DataItem
      label="Pending withdrawals"
      data={pendingWithdrawal}
      tooltipHint="Total amount of SDT tokens that were requested for withdrawal from all your accounts and are currently waiting to be approved. For security reasons please allow up to 24 hours before you will be able to withdraw the tokens to your wallet."
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-hourglass'}
    /> */}
  </div>
);
