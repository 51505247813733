import { roundNumber } from 'helpers';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const TruncatedNumber: React.FC<{
  value: string;
  precision?: number;
}> = ({ value, precision = 6 }) => {
  const result = roundNumber(value);

  if (result.length < value.length)
    return (
      <OverlayTrigger overlay={<Tooltip>{value}</Tooltip>}>
        <span>
          <span style={{ cursor: 'help' }}>{result}</span>
          &#8230;
        </span>
      </OverlayTrigger>
    );
  else return <span>{result}</span>;
};
