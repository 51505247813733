import { NetworkParams } from '../models';

export const NetworksParams: Record<string, NetworkParams> = {
  BscMainnet: {
    chainId: 56,
    name: 'BNB Smart Chain',
    addChainParams: {
      chainName: 'BNB Smart Chain',
      chainId: 56,
      nativeCurrency: {
        name: 'BNB',
        decimals: 18,
        symbol: 'BNB',
      },
      rpcUrls: ['https://bsc-dataseed.bnbchain.org/'],
      blockExplorerUrls: ['https://bscscan.com/'],
    },
  },
  BscTestnet: {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    addChainParams: {
      chainName: 'BNB Smart Chain Testnet',
      chainId: 97,
      nativeCurrency: {
        name: 'tBNB',
        decimals: 18,
        symbol: 'tBNB',
      },
      rpcUrls: ['https://bsc-testnet-dataseed.bnbchain.org'],
      blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
  },
  BaseMainnet: {
    chainId: 8453,
    name: 'Base Mainnet',
    addChainParams: {
      chainName: 'Base Mainnet',
      chainId: 8453,
      nativeCurrency: {
        name: 'ETH',
        decimals: 18,
        symbol: 'ETH',
      },
      rpcUrls: ['https://base-rpc.publicnode.com'],
      // rpcUrls: ['https://base.drpc.org'],
      // rpcUrls: ['https://mainnet.base.org/'],
      blockExplorerUrls: ['https://basescan.org/'],
    },
  },
  BaseTestnet: {
    chainId: 84532,
    name: 'Base Sepolia',
    addChainParams: {
      chainName: 'Base Sepolia',
      chainId: 84532,
      nativeCurrency: {
        name: 'ETH',
        decimals: 18,
        symbol: 'ETH',
      },
      rpcUrls: ['https://base-sepolia-rpc.publicnode.com'],
      // rpcUrls: ['https://sepolia.base.org/'],
      blockExplorerUrls: ['https://sepolia.basescan.org/'],
    },
  },
};
