import { toWei } from 'helpers';
import { StakingPoolItemData } from 'models';

export const postWithdrawInstant = async (
  stakingPoolItemData: StakingPoolItemData,
  availableForWithdrawal: string,
  decimals: number,
  connectedAddress: string
): Promise<unknown> => {
  const availableForWithdrawalWei = toWei(availableForWithdrawal, decimals);

  return await stakingPoolItemData.stakingPoolItemContract.methods
    .withdrawInstantWithPenalty(availableForWithdrawalWei)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};

export const postWithdraw = async (
  stakingPoolItemData: StakingPoolItemData,
  withdrawValue: string,
  decimals: number,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const withdrawValueWei = toWei(withdrawValue, decimals);

  return await stakingPoolItemData.stakingPoolItemContract.methods
    .withdraw(withdrawValueWei)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};
