import { StakingPoolData, TokenBalances, TokensData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalances, selectStakingPools, selectTokensData } from 'store';

import StakingPool from './StakingPool';

const StakingPools: React.FC = () => {
  const balances: TokenBalances = useSelector(selectBalances);
  const tokensData: TokensData = useSelector(selectTokensData);
  const stakingPoolsData: StakingPoolData[] = useSelector(selectStakingPools);

  const stakingPools = stakingPoolsData?.map(
    (stakingPoolData: StakingPoolData, index: number) => (
      <StakingPool
        key={`staking-pool-component-${index}`}
        stakingPoolData={stakingPoolData}
        tokensData={tokensData}
        balance={balances[stakingPoolData.poolStakingTokenSymbol]}
      />
    )
  );

  return <div className="staking-pools">{tokensData && stakingPools}</div>;
};

export default StakingPools;
