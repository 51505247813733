/* eslint-disable @typescript-eslint/no-explicit-any */
import BigNumber from 'bignumber.js';
import allocationContractJSON from 'config/bsc/contracts/allocation/SkynityAllocationVaultBUSD.json';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { NetworksParams } from '../config';
import { isProduction } from './environment.helper';

type PresalePool = {
  name: string;
  address?: string;
  chainId?: number;
  fromAPI?: string;
  decimals?: number;
  price?: number;
  disabled?: boolean;
};

const presalePools: PresalePool[] = [
  {
    name: 'PlayNity PEX #1 PLY',
    address: '0x96929Aad81bF1b9Aa316fAef251fBC4F40450B77',
    chainId: 56,
    decimals: 6,
    price: 2,
  },
  {
    name: 'PlayNity PEX #1 BUSD',
    address: '0x9e75003062F88CB2FA7c33DB26e427E55b2C9010',
    chainId: 56,
    decimals: 18,
    price: 0.036,
  },
  {
    name: 'Private BUSD',
    address: '0xD53c7fC65f5ffd635C1340fDdBe2B775DbD62225',
    chainId: 56,
    decimals: 18,
    price: 0.04,
  },
  {
    name: 'PlayNity PEX #2 BUSD',
    address: '0x036aE88E3a55153FACF0A5D68119F69d90ba681F',
    chainId: 56,
    decimals: 18,
    price: 0.038,
  },
  {
    name: 'Public BUSD',
    address: '0x51bb6CBcEcB4d2f24D4D7197f6ceC479abb35125',
    chainId: 56,
    decimals: 18,
    price: 0.05,
  },
  {
    name: 'Public PLY',
    address: '0x8d2c00796B0A7CFeB1Da722328C48072ec2C99Cd',
    chainId: 56,
    decimals: 6,
    price: 8,
  },
  {
    name: 'Private USDT',
    address: '0x6B57f08fDcA61042002F85b80A66c74b5f0d4f03',
    chainId: 56,
    decimals: 18,
    price: 0.04,
  },
  {
    name: 'Seed USDT',
    address: '0x2a65F8D1F0A5D81Bb2bCb70DFe6F6166cf79Ca5c',
    chainId: 56,
    decimals: 18,
    price: 0.032,
  },
  {
    name: 'Public USDC (base)',
    address: '0x89ae191c72234E26F75129f88f9E931FCCb5dbe1',
    chainId: 8453,
    decimals: 6,
    price: 0.05,
  },
  {
    name: 'Other',
    fromAPI: 'https://api.playnity.io/api/sdt-presale/manual-alloc',
    // disabled: true,
  },
];

const getAcquiredSdt = async (
  connectedAddress: string,
  presalePool: PresalePool
): Promise<BigNumber> => {
  if (presalePool.address) {
    const rpcUrls = Object.values(NetworksParams).find(
      (network) => network.chainId === presalePool.chainId
    )?.addChainParams?.rpcUrls;
    if (!rpcUrls || rpcUrls.length === 0) {
      return new BigNumber(0);
    }
    const web3 = new Web3(rpcUrls[0]);
    const contract = new web3.eth.Contract(
      allocationContractJSON.abi as AbiItem[],
      presalePool.address
    );
    const addressStats: {
      totalSupply: string;
      allocationLimit: string;
      depositedAmount: string;
      allocationLeft: string;
      // isAllocated;
      // blockTimestamp;
    } = await contract.methods.getAddressStats(connectedAddress).call();
    return new BigNumber(addressStats.depositedAmount)
      .shiftedBy(-presalePool.decimals)
      .div(presalePool.price);
  } else if (presalePool.fromAPI) {
    const response = await fetch(
      `${presalePool.fromAPI}?address=${connectedAddress}`
    );
    const data: { amount: number } = await response.json();

    return new BigNumber(data.amount);
  }
};

export const getTotalAcquiredSDT = async (
  connectedAddress: string
): Promise<{
  total: string;
  perPool?: { name: string; amount: string }[];
}> => {
  let partialSDT = await Promise.all(
    presalePools
      .filter((pool) => !pool.disabled)
      .map(async (pool) => ({
        name: pool.name,
        amount: await getAcquiredSdt(connectedAddress, pool),
      }))
  );
  // partialSDT = [...partialSDT];
  if (isProduction())
    partialSDT = partialSDT.filter((pool) => !pool.amount.isZero());
  const totalSDT = partialSDT.reduce<BigNumber>(
    (sum, current) => sum.plus(current.amount),
    new BigNumber(0)
  );

  return {
    total: totalSDT.toString(10),
    perPool: partialSDT.map((pool) => ({
      amount: pool.amount.toString(10),
      name: pool.name,
    })),
  };
};
