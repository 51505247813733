/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import {
  BondedTokens,
  ButtonWithSpinner,
  LockedTokens,
  Message,
  PercentageButtons,
} from 'components';
import {
  humanizeDuration,
  MessageText,
  MessageType,
  numbersOnly,
  StakingPoolActions,
} from 'helpers';
import {
  MainTokenSymbol,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemWithdrawTabContentProps {
  loading: boolean;
  unstakingValue: string;
  staking: StakingPoolItemStakingData;
  tokenData: TokenData;
  stakingPoolItemData: StakingPoolItemData;
  onUnstake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onWithdraw: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => void;
  onUnstakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number, value: string, type: string) => void;
}

export const StakingPoolItemWithdrawTabContent: React.FC<
  StakingPoolItemWithdrawTabContentProps
> = ({
  loading,
  unstakingValue,
  staking,
  tokenData,
  stakingPoolItemData,
  onUnstake,
  onWithdraw,
  onUnstakeValueChange,
  onApplyPercentage,
}) => {
  const isBondedPoolType = staking?.unbondingPeriod !== undefined;
  const isLockedPoolType = staking?.lockPeriod !== undefined;
  const hasBondedTokens = !!staking.bondedTokensData?.length;

  const unstakingValueBN = new BigNumber(unstakingValue);
  const stakedTokensBN = new BigNumber(staking.stakedTokens);
  const availableForWithdrawalBN = new BigNumber(
    staking.availableForWithdrawal
  );

  const inputDisabled = isLockedPoolType
    ? availableForWithdrawalBN.isEqualTo(0)
    : stakedTokensBN.isEqualTo(0);
  const percentageButtonsDisabled =
    loading || isLockedPoolType
      ? availableForWithdrawalBN.isEqualTo(0)
      : stakedTokensBN.isEqualTo(0);

  const unbondingsExceeded =
    staking.bondedTokensData?.length >= +staking.maxUnbondingsPerAccount;

  const unstakeButtonDisabled =
    !+staking.stakedTokens ||
    !+unstakingValue ||
    unstakingValueBN.isGreaterThan(stakedTokensBN) ||
    unbondingsExceeded;

  const withdrawButtonDisabled =
    +staking.availableForWithdrawal == 0 ||
    +unstakingValue == 0 ||
    unstakingValueBN.isGreaterThan(availableForWithdrawalBN);

  const renderLockedTokens = (): JSX.Element =>
    hasBondedTokens && (
      <LockedTokens
        staking={staking}
        lockedTokensData={staking.bondedTokensData}
        tokenSymbol={tokenData.symbol as MainTokenSymbol}
      />
    );

  const renderBondedTokens = (): JSX.Element =>
    hasBondedTokens && (
      <BondedTokens
        loading={loading}
        staking={staking}
        bondedTokensData={staking.bondedTokensData}
        tokenSymbol={tokenData.symbol as MainTokenSymbol}
        onWithdraw={(event, instant?): void => onWithdraw(event, instant)}
      />
    );

  const renderLockedMessage = (): JSX.Element =>
    hasBondedTokens ? (
      <span>
        You can only withdraw the amount of stakes for which the lock period of{' '}
        <strong>{humanizeDuration(+staking.lockPeriod)}</strong> since staking
        has already elapsed (marked as &quot;Available for withdrawal&quot; in
        the stakes list below).
      </span>
    ) : (
      <Message
        messageType={MessageType.Primary}
        descriptionText={MessageText.NoTokensStaked}
      />
    );

  const renderBondingMessage = (): JSX.Element => (
    <div>
      {+staking.stakedTokens > 0 && (
        <div>
          Your staked tokens must be unstaked first and will be available for
          withdrawal after an unbonding period of{' '}
          <strong>{humanizeDuration(+staking.unbondingPeriod)}</strong>, or
          instantly - if you pay an instant withdrawal penalty of{' '}
          <strong>{staking.instantWithdrawalPenalty}%</strong>.
        </div>
      )}
      {+staking.stakedTokens == 0 && (
        <Message
          messageType={MessageType.Primary}
          descriptionText={MessageText.NoTokensStaked}
        />
      )}
      {+staking.totalInUnbonding == 0 && +staking.stakedTokens > 0 && (
        <Message
          messageType={MessageType.Primary}
          descriptionText="You don't have any tokens available for withdrawal. Please
            unstake your tokens first."
        />
      )}
      {unbondingsExceeded && (
        <Message
          messageType={MessageType.Primary}
          descriptionText={`You cannot have more active unbondings than ${staking.maxUnbondingsPerAccount} from one address. Please withdraw the tokens first.`}
        />
      )}
    </div>
  );

  const renderButton = (): JSX.Element => {
    if (isBondedPoolType) {
      return (
        <ButtonWithSpinner
          text="Unstake"
          classes="btn btn-primary section-action-button"
          onClick={onUnstake}
          disabled={unstakeButtonDisabled}
          loading={loading}
        />
      );
    } else if (isLockedPoolType) {
      return (
        <ButtonWithSpinner
          text="Withdraw"
          classes="btn btn-primary section-action-button"
          onClick={onWithdraw}
          disabled={withdrawButtonDisabled}
          loading={loading}
        />
      );
    }
  };

  return (
    <Tab.Pane eventKey="withdraw" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="unstake-section">
          <div className="unstake-section-description">
            {isLockedPoolType && renderLockedMessage()}
            {isBondedPoolType && renderBondingMessage()}
          </div>
          {isLockedPoolType && hasBondedTokens && (
            <div>
              {renderLockedTokens()}
              <hr />
            </div>
          )}

          {((isLockedPoolType && hasBondedTokens) ||
            (isBondedPoolType &&
              +staking.stakedTokens > 0 &&
              !unbondingsExceeded)) && (
            <>
              <div className="unstake-section-actions">
                <input
                  className="s-input"
                  placeholder="0.00"
                  onChange={onUnstakeValueChange}
                  value={unstakingValue}
                  onKeyPress={numbersOnly}
                  disabled={inputDisabled}
                />

                <PercentageButtons
                  disabled={percentageButtonsDisabled}
                  variant={isLockedPoolType ? 'aquamarine' : ''}
                  applyPercentage={(percentage: number): void =>
                    onApplyPercentage(
                      percentage,
                      isLockedPoolType
                        ? staking.availableForWithdrawal
                        : staking.stakedTokens,
                      isLockedPoolType
                        ? StakingPoolActions.Withdraw
                        : StakingPoolActions.Unstake
                    )
                  }
                />

                {renderButton()}
                {staking.unstakingFee && (
                  <div className="text-center small">
                    Unstaking fee of {+staking.unstakingFee * 100}% will be
                    deducted
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {unstakingValueBN.isGreaterThan(stakedTokensBN) && (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.UnstakingValueNotGreaterThanStaked}
          />
        )}
        {staking?.unbondingPeriod !== undefined && renderBondedTokens()}
      </div>
    </Tab.Pane>
  );
};
