import { StakingPoolItemData } from 'models';

export const postGetReward = async (
  stakingPoolItemData: StakingPoolItemData,
  connectedAddress: string
): Promise<void> =>
  await stakingPoolItemData.stakingPoolItemContract.methods.getReward().send({
    from: connectedAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  });
