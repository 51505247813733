/* eslint-disable max-lines */
import BigNumber from 'bignumber.js';
import { fromWei, getContract, skynityConfig } from 'helpers';
import {
  LPData,
  LPPairsContracts,
  LPsData,
  LPTokensPrices,
  LPTokenSymbol,
  MainTokenSymbol,
  PairConfig,
  TokenBalances,
  TokenConfig,
  TokenData,
  TokenPrices,
  TokensData,
  TokenSymbol,
} from 'models';
import { from, Observable, of } from 'rxjs';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

export class ContractService {
  // TODO: Fix Promise / Observable
  public static getBalance(
    tokenData: TokenData,
    connectedAddress: string
  ): Observable<string> {
    const getBalance = async (): Promise<string> => {
      const weiBalance = await tokenData.tokenContract.methods
        .balanceOf(connectedAddress)
        .call();

      return fromWei(weiBalance, tokenData.decimals);
    };

    return from(getBalance());
  }

  public static getBalances(
    tokensData: TokenData[],
    connectedAddress: string
  ): Observable<TokenBalances> {
    const getBalances = async (): Promise<
      Partial<Record<TokenSymbol, string>>
    > => {
      const balances: TokenBalances = {} as TokenBalances;

      for (const tokenData of tokensData) {
        const weiBalance = await tokenData.tokenContract.methods
          .balanceOf(connectedAddress)
          .call();

        balances[tokenData.symbol as MainTokenSymbol] = fromWei(
          weiBalance,
          tokenData.decimals
        );
      }

      return balances;
    };

    return from(getBalances());
  }

  public static getTokensContracts(
    networkId: string,
    web3: Web3,
    gasPrice: string
  ): Observable<TokensData> {
    const tokenContracts: TokensData = {} as TokensData;

    Object.entries(skynityConfig.tokens).forEach(
      ([tokenSymbol, tokenConfig]: [string, TokenConfig]) => {
        const tokenContract = getContract(
          web3,
          tokenConfig.tokenContractJSON,
          networkId,
          gasPrice
        );

        tokenContracts[tokenSymbol as TokenSymbol] = {
          tokenContract,
          name: tokenConfig.name,
          symbol: tokenConfig.symbol,
          decimals: tokenConfig.decimals,
        };
      }
    );

    return of(tokenContracts);
  }

  public static getNftTokenContract(
    networkId: string,
    web3: Web3,
    gasPrice: string
  ): Observable<Contract> {
    const nftContract = getContract(
      web3,
      skynityConfig.nftContractJSON,
      networkId,
      gasPrice
    );

    return of(nftContract);
  }

  public static getGameBridgeContract(
    networkId: string,
    web3: Web3,
    gasPrice: string
  ): Observable<Contract> {
    const gameBridgeContract = getContract(
      web3,
      skynityConfig.gameBridgeContractJSON,
      networkId,
      gasPrice
    );

    return of(gameBridgeContract);
  }

  public static getLPPairsContracts(
    networkId: string,
    web3: Web3,
    gasPrice: string
  ): Observable<LPPairsContracts> {
    const pairsContracts: LPPairsContracts = {} as LPPairsContracts;

    Object.entries(skynityConfig.LPPairs).forEach(
      ([lpTokenSymbol, pairConfig]: [string, PairConfig]) => {
        const pairContract = getContract(
          web3,
          pairConfig.contractJSON,
          networkId,
          gasPrice
        );

        pairsContracts[lpTokenSymbol as LPTokenSymbol] = pairContract;
      }
    );

    return of(pairsContracts);
  }

  // TODO: Fix Promise / Observable
  public static getLPsData(
    tokensData: TokensData,
    lpPairsContract: LPPairsContracts
  ): Observable<LPsData> {
    const getLPData = async (
      tokenData: TokenData,
      lpPairContract: Contract,
      lpTokenData: TokenData
    ): Promise<{
      tokensInLPPair: string;
      totalLP: string;
    }> => {
      const totalLPBN = await lpPairContract.methods.totalSupply().call();
      const totalLP = fromWei(totalLPBN, lpTokenData.decimals);
      const reserves = await lpPairContract.methods.getReserves().call();
      const token0Address: string = await lpPairContract.methods
        .token0()
        .call();

      // ? First token in pair is  token, needed for testnet pancake
      const reserve0 =
        token0Address.toLocaleLowerCase() ===
        tokenData.tokenContract.options.address.toLocaleLowerCase()
          ? reserves._reserve0
          : reserves._reserve1;
      const tokensInLPPair = fromWei(reserve0, tokenData.decimals);

      return {
        tokensInLPPair,
        totalLP,
      };
    };

    const getLPsData = async (): Promise<any> => {
      const SDTUSDCData = await getLPData(
        tokensData.SDT,
        lpPairsContract.LPSDTUSDC,
        tokensData.LPSDTUSDC
      );

      return {
        [LPTokenSymbol.SDTUSDC]: SDTUSDCData,
      };
    };

    return from(getLPsData());
  }

  public static getTokensPrices = (
    stableTokenPrice: string,
    tokensData: Partial<Record<TokenSymbol, TokenData>>,
    stableTokenData: TokenData,
    lpPairsContracts: LPPairsContracts
  ): Observable<TokenPrices> => {
    const getPrice = async (
      lpPairContract: Contract,
      askedtokenData: TokenData,
      secondTokenData: TokenData
    ): Promise<BigNumber> => {
      const token0Address: string = await lpPairContract.methods
        .token0()
        .call();
      const reserves = await lpPairContract.methods.getReserves().call();
      let reserve0 = 0;
      let reserve1 = 0;

      const isFirstTokenInPair =
        token0Address.toLocaleLowerCase() ===
        askedtokenData.tokenContract.options.address.toLocaleLowerCase();

      if (isFirstTokenInPair) {
        reserve0 = reserves._reserve0;
        reserve1 = reserves._reserve1;
      } else {
        reserve0 = reserves._reserve1;
        reserve1 = reserves._reserve0;
      }

      const tokenPriceBN = new BigNumber(reserve1)
        .div(new BigNumber(reserve0))
        .shiftedBy(-(secondTokenData.decimals - askedtokenData.decimals));

      return tokenPriceBN.times(new BigNumber(stableTokenPrice));
    };

    // TODO: Remove hardcoding
    const getPrices = async (): Promise<TokenPrices> => {
      // SDT
      const SDTPriceInUSDC = await getPrice(
        lpPairsContracts.LPSDTUSDC,
        tokensData.SDT,
        tokensData.USDC
      );

      return {
        [MainTokenSymbol.SDT]: SDTPriceInUSDC.toString(10),
      };
    };

    return from(getPrices());
  };

  public static getLPTokensPrices = (
    tokensPrices: TokenPrices,
    LPsData: LPsData
  ): Observable<LPTokensPrices> => {
    const getLPTokenPrice = (tokenPrice: string, lpData: LPData): string => {
      const tokensInLPPairBN = new BigNumber(lpData.tokensInLPPair);
      const tokenPriceBN = new BigNumber(tokenPrice);
      const totalLPBN = new BigNumber(lpData.totalLP);
      const LPTokenPrice = totalLPBN.isGreaterThan(0)
        ? tokensInLPPairBN
            .times(2)
            .times(tokenPriceBN)
            .div(totalLPBN)
            .toString(10)
        : '0';
      return LPTokenPrice;
    };

    // TODO: Remove hardcoding
    return of({
      [LPTokenSymbol.SDTUSDC]: getLPTokenPrice(
        tokensPrices.SDT,
        LPsData.LPSDTUSDC
      ),
    });
  };
}
