import React, { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

interface ButtonWithSpinnerProps {
  classes: string;
  loading: boolean;
  text?: string;
  disabled?: boolean;
  spinnerVariant?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ButtonWithSpinner: React.FC<
  PropsWithChildren<ButtonWithSpinnerProps>
> = ({
  children,
  classes,
  loading,
  disabled,
  text,
  spinnerVariant,
  onClick,
}) => (
  <button
    className={`btn ${classes}`}
    onClick={(event): void => onClick(event)}
    disabled={disabled || loading}
  >
    <div className="d-flex position-relative justify-content-center align-items-center">
      {loading && (
        <Spinner
          style={{ position: 'absolute', left: '50%', right: '50%' }}
          animation="border"
          role="status"
          variant={spinnerVariant}
          size="sm"
        />
      )}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {text || children}
      </div>
    </div>
  </button>
);
