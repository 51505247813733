/* eslint-disable max-lines */
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {
  Message,
  StakingPoolItemClaimTabContent,
  StakingPoolItemHeader,
  StakingPoolItemStakeTabContent,
  StakingPoolItemWithdrawTabContent,
} from 'components';
import {
  getStakingPoolItemData,
  isCorrectValue,
  MAX_UINT256,
  MessageText,
  MessageType,
  postGetReward,
  postStake,
  postUnstake,
  postWithdraw,
  postWithdrawInstant,
  StakingPoolActions,
} from 'helpers';
import {
  LPPairsContracts,
  LPsData,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  StakingPoolType,
  TokenPrices,
  TokensData,
  TokenSymbol,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import { Accordion, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBalances,
  getLPsData,
  getTokensPrices,
  selectConnectedAddress,
  selectLpPairsContracts,
  selectLPsData,
  selectStablecoinPrice,
  selectTokensPrices,
} from 'store';

import {
  initialStakingPoolDetailsState,
  StakingPoolDetailsActions,
  stakingPoolDetailsReducer,
} from '../store/reducer';
import { StakingPoolItemDetails } from './StakingPoolItemDetails';

interface StakingPoolItemProps {
  index: number;
  balance: string;
  stakingPoolItemsData: StakingPoolItemData[];
  stakingPoolItemData: StakingPoolItemData;
  stakingTokenSymbol: TokenSymbol;
  tokensData: TokensData;
  stakingPoolType: StakingPoolType;
  isActive: boolean;
  // moveBondDestinationPoolItemData: StakingPoolItemData;
  // onMoveBondFinished: (destinationPoolItemData: StakingPoolItemData) => void;
}

export const StakingPoolItem: React.FC<StakingPoolItemProps> = ({
  index,
  balance,
  stakingPoolItemData,
  stakingPoolItemsData,
  stakingTokenSymbol,
  tokensData,
  stakingPoolType,
  isActive,
  // moveBondDestinationPoolItemData,
  // onMoveBondFinished,
}) => {
  const [state, dispatch] = useReducer(
    stakingPoolDetailsReducer,
    initialStakingPoolDetailsState
  );
  const dispatchApp = useDispatch();
  const LPPairsContracts: LPPairsContracts = useSelector(
    selectLpPairsContracts
  );
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const tokensPrices: TokenPrices = useSelector(selectTokensPrices);
  const stableTokenPrice: string = useSelector(selectStablecoinPrice);
  const LPsData: LPsData = useSelector(selectLPsData);

  const getData = (): void => {
    getStakingPoolDetailsData();
    dispatchApp(
      getBalances({
        tokensData: Object.values(tokensData),
        connectedAddress,
      })
    );
    dispatchApp(
      getTokensPrices({
        stableTokenPrice,
        tokensData,
        stableTokenData: tokensData.USDC,
        LPPairsContracts,
      })
    );

    dispatchApp(
      getLPsData({
        tokensData,
        LPPairsContracts: LPPairsContracts,
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   const isDestinationStakingPoolItem =
  //     moveBondDestinationPoolItemData?.poolItemName ===
  //     stakingPoolItemData?.poolItemName;

  //   if (isDestinationStakingPoolItem) {
  //     getData();
  //   }
  // }, [moveBondDestinationPoolItemData]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (state.loading === false) {
      getData();
    }
  }, [state.loading]);

  const getStakingPoolDetailsData = async (): Promise<void> => {
    try {
      const stakingPoolItemStakingData: StakingPoolItemStakingData =
        await getStakingPoolItemData(
          stakingPoolItemData,
          stakingPoolItemsData,
          tokensData[stakingTokenSymbol],
          connectedAddress
        );

      dispatch({
        type: StakingPoolDetailsActions.SetStaking,
        payload: stakingPoolItemStakingData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await tokensData[stakingTokenSymbol].tokenContract.methods
        .approve(
          stakingPoolItemData.stakingPoolItemContract.options.address,
          MAX_UINT256
        )
        .send({
          from: connectedAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });

      // TODO: Find better way for refresh
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: '',
      });
    } catch (error) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  };

  const onStake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postStake(
        stakingPoolItemData,
        state.stakingValue,
        tokensData[stakingTokenSymbol].decimals,
        connectedAddress
      );
    } catch (error) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({ type: StakingPoolDetailsActions.SetStakingValue, payload: '' });
  };

  const onUnstake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postUnstake(
        stakingPoolItemData,
        state.unstakingValue,
        tokensData[stakingTokenSymbol].decimals,
        connectedAddress
      );
    } catch (error) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });
  };

  const onWithdraw = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ): Promise<void> => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      if (instant) {
        await postWithdrawInstant(
          stakingPoolItemData,
          state.staking.totalInUnbonding,
          tokensData[stakingTokenSymbol].decimals,
          connectedAddress
        );
      } else {
        await postWithdraw(
          stakingPoolItemData,
          state.staking.lockPeriod === undefined
            ? state.staking.availableForWithdrawal
            : state.unstakingValue,
          tokensData[stakingTokenSymbol].decimals,
          connectedAddress
        );
      }
    } catch (error) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });
  };

  // const onMoveBond = async (destinationPoolItemData: StakingPoolItemData) => {
  //   dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
  //   dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

  //   try {
  //     await postMoveBond(
  //       stakingPoolItemData,
  //       destinationPoolItemData,
  //       connectedAddress
  //     );

  //     onMoveBondFinished(destinationPoolItemData);
  //     // eslint-disable-next-line @typescript-eslint/no-empty-function
  //   } catch (err) {
  //     dispatch({
  //       type: StakingPoolDetailsActions.SetErrorMessage,
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       payload: (err as any).message,
  //     });
  //   }

  //   dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  // };

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postGetReward(stakingPoolItemData, connectedAddress);
    } catch (error) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  };

  const onSelectTab = (): void => {
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });

    // TODO: Fix - fired also on setLoading
    getStakingPoolDetailsData();
  };

  const onStakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokensData[stakingTokenSymbol].decimals)) {
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: value,
      });
    }
  };

  const onUnstakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokensData[stakingTokenSymbol].decimals)) {
      dispatch({
        type: StakingPoolDetailsActions.SetUnstakingValue,
        payload: value,
      });
    }
  };

  const onApplyPercentage = (
    percentage: number,
    value: string,
    actionType: string
  ): void => {
    const BNValue = new BigNumber(value);
    const roundedNumber = BigNumber(
      BNValue.times(percentage)
        .div(100)
        .toFixed(tokensData[stakingTokenSymbol].decimals)
    ).toString(10);

    switch (actionType) {
      case StakingPoolActions.Stake: {
        dispatch({
          type: StakingPoolDetailsActions.SetStakingValue,
          payload: roundedNumber,
        });

        break;
      }

      case StakingPoolActions.Unstake:
      case StakingPoolActions.Withdraw: {
        dispatch({
          type: StakingPoolDetailsActions.SetUnstakingValue,
          payload: roundedNumber,
        });

        break;
      }
    }
  };

  const stakingPoolItemClassNames = classNames(
    'staking-pool-item',
    stakingPoolItemData?.poolItemtype.toLocaleLowerCase().replaceAll(' ', '-'),
    { inactive: !isActive }
  );

  return (
    <div className={stakingPoolItemClassNames}>
      <Accordion.Item
        eventKey={index.toString()}
        className={classNames({ loading: !state.staking })}
      >
        <Accordion.Header
          onClick={(): void => {
            isActive &&
              state.staking &&
              dispatch({
                type: StakingPoolDetailsActions.SetIsExpanded,
                payload: !state.isExpanded,
              });
          }}
          as={'div'}
          className={classNames({ loading: !state.staking })}
        >
          <StakingPoolItemHeader
            stakingPoolItemData={stakingPoolItemData}
            staking={state.staking}
            tokensPrices={tokensPrices}
            stableTokenPrice={stableTokenPrice}
            LPsData={LPsData}
            stakingPoolType={stakingPoolType}
            stakingTokenData={tokensData[stakingTokenSymbol]}
          />
        </Accordion.Header>

        {isActive && state.staking && (
          <Accordion.Body>
            <div className="staking-pool-item-body">
              <Tab.Container
                id="tabs-container"
                defaultActiveKey="stake"
                onSelect={onSelectTab}
              >
                <Nav variant="pills" className="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="stake" disabled={state.loading}>
                      Stake
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="withdraw" disabled={state.loading}>
                      Withdraw
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="claim" disabled={state.loading}>
                      Claim
                    </Nav.Link>
                  </Nav.Item>
                  {/* {state.staking.moveBondAvailable ? (
                      <Nav.Item>
                        <Nav.Link eventKey="movebond" disabled={state.loading}>
                          Move
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )} */}
                </Nav>

                <StakingPoolItemDetails
                  balance={balance}
                  staking={state.staking}
                  symbol={stakingTokenSymbol}
                />

                <Tab.Content className="tabs">
                  <StakingPoolItemStakeTabContent
                    loading={state.loading}
                    balance={balance}
                    stakingValue={state.stakingValue}
                    staking={state.staking}
                    stakingPoolItemData={stakingPoolItemData}
                    stakingTokenData={tokensData[stakingTokenSymbol]}
                    onStake={(event): Promise<void> => onStake(event)}
                    onApprove={(event): Promise<void> => onApprove(event)}
                    onStakeValueChange={(event): void =>
                      onStakeValueChange(event)
                    }
                    onApplyPercentage={(percentage, value): void =>
                      onApplyPercentage(
                        percentage,
                        value,
                        StakingPoolActions.Stake
                      )
                    }
                  />
                  <StakingPoolItemWithdrawTabContent
                    loading={state.loading}
                    unstakingValue={state.unstakingValue}
                    staking={state.staking}
                    tokenData={tokensData[stakingTokenSymbol]}
                    stakingPoolItemData={stakingPoolItemData}
                    onWithdraw={(event, instant): Promise<void> =>
                      onWithdraw(event, instant)
                    }
                    onUnstake={(event): Promise<void> => onUnstake(event)}
                    onUnstakeValueChange={(event): void =>
                      onUnstakeValueChange(event)
                    }
                    onApplyPercentage={(percentage, value, actionType): void =>
                      onApplyPercentage(percentage, value, actionType)
                    }
                  />
                  <StakingPoolItemClaimTabContent
                    loading={state.loading}
                    staking={state.staking}
                    onClaim={(event): Promise<void> => onClaim(event)}
                  />
                  {/* {state.staking.moveBondAvailable ? (
                      <StakingPoolItemMoveBondTabContent
                        loading={state.loading}
                        staking={state.staking}
                        connectedAddress={connectedAddress}
                        stakingPoolItemsData={stakingPoolItemsData}
                        stakingTokenData={stakingTokenData}
                        moveBondDestinationStakingPoolItems={
                          state.staking.moveBondDestinationStakingPoolItems
                        }
                        stakingPoolType={stakingPoolType}
                        onMoveBond={(
                          destinationPoolItemData: StakingPoolItemData
                        ) => onMoveBond(destinationPoolItemData)}
                      />
                    ) : (
                      ''
                    )} */}
                  {state.errorMessage && (
                    <Message
                      descriptionText={
                        state.errorMessage.includes('404')
                          ? `${state.errorMessage} ${MessageText.Fees}`
                          : state.errorMessage
                      }
                      messageType={MessageType.Error}
                    />
                  )}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Accordion.Body>
        )}
      </Accordion.Item>
    </div>
  );
};
