import { StakingPoolData, StakingPoolItemData, TokensData } from 'models';
import React from 'react';
import { Accordion } from 'react-bootstrap';

import { StakingPoolItem } from './StakingPoolItem';

interface StakingPoolProps {
  balance: string;
  tokensData: TokensData;
  stakingPoolData: StakingPoolData;
}

const StakingPool: React.FC<StakingPoolProps> = ({
  balance,
  tokensData,
  stakingPoolData,
}) => {
  // const [moveBondDestinationPoolItemData, setMoveBondDestinationPoolItemData] =
  //   useState(null);
  const stakingPoolClassName = `staking-pool section-container ${stakingPoolData.poolType
    .toLocaleLowerCase()
    .replaceAll(' ', '-')}`;
  const stakingPoolItems = (
    <Accordion>
      {stakingPoolData.poolItems?.map(
        (stakingPoolItemData: StakingPoolItemData, index: number) => (
          <StakingPoolItem
            key={`staking-pool-item-component-${index}`}
            index={index}
            balance={balance}
            stakingPoolItemData={stakingPoolItemData}
            stakingPoolItemsData={stakingPoolData.poolItems}
            stakingTokenSymbol={stakingPoolData.poolStakingTokenSymbol}
            tokensData={tokensData}
            stakingPoolType={stakingPoolData.poolType}
            isActive={stakingPoolItemData.addressCanStake}
            // moveBondDestinationPoolItemData={moveBondDestinationPoolItemData}
            // onMoveBondFinished={(moveBondDestinationPoolItemData) =>
            //   setMoveBondDestinationPoolItemData(
            //     moveBondDestinationPoolItemData
            //   )
            // }
          />
        )
      )}
    </Accordion>
  );

  return (
    <div className={stakingPoolClassName}>
      <div className="staking-pool-header">
        <span className="pool-type">{stakingPoolData.poolName}</span>
        {stakingPoolData.poolDescription}
        {/* {stakingPoolData.poolSubtype === StakingPoolSubtype.PEX && (
          <OverlayTrigger
            overlay={
              <Tooltip id="staking-pool-tooltip">
                Locked staking pools can be unlocked by gaining PEX points.
              </Tooltip>
            }
          >
            <i className="far fa-question-circle ml-8 pointer"></i>
          </OverlayTrigger>
        )} */}
      </div>
      {stakingPoolItems}
      {/* {stakingPoolData.poolType === StakingPoolType.SC && (
        <div style={{ marginTop: '20px', fontSize: '12px' }}>
          * Total stablecoin staking limit sum up (achieving higher levels does
          not cancel access to lower-level staking pools)
        </div>
      )} */}
    </div>
  );
};

export default StakingPool;
