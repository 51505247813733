import { Contract } from 'web3-eth-contract';

import { ContractJSON } from './contract.model';

export enum TokenName {
  Playnity = 'Playnity',
  Skynity = 'Skynity',
  LPSkynity = 'LP-Skynity',
  LPPLaynity = 'LP-Playnity',
  Stablecoin = 'Stablecoin',
}

export enum MainTokenSymbol {
  SDT = 'SDT',
}

export const rSDTTokenSymbol = 'rSDT';

export enum StableTokenSymbol {
  BUSD = 'BUSD',
  USDT = 'USDT',
  USDC = 'USDC',
}

// TODO: Update contact
export enum LPTokenSymbol {
  'SDTBUSD' = 'LPSDTBUSD',
  'SDTUSDT' = 'LPSDTBUSD', // ????
  'SDTUSDC' = 'LPSDTUSDC',
}

export type TokenSymbol = MainTokenSymbol | StableTokenSymbol | LPTokenSymbol;

export type TokensData = Partial<Record<TokenSymbol, TokenData>>;

export interface TokenData {
  name: TokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContract: Contract;
}

export interface TokenConfig {
  name: TokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContractJSON: ContractJSON;
}

export type TokenBalances = Partial<Record<TokenSymbol, string>>;
export type TokenPrices = Partial<Record<TokenSymbol, string>>;
