import { GameAccount, Nft } from 'models';
import React from 'react';

import skynityIsland from 'assets/svg/skynity-island.svg';

interface GameAccountItemHeaderProps {
  account: GameAccount;
  assignedNft: Nft;
}

export const GameAccountItemHeader: React.FC<GameAccountItemHeaderProps> = ({
  account,
  assignedNft,
}) => (
  <div className="staking-pool-item-header flex-nowrap">
    <div className="name basis-0">{account.name}</div>
    <div className="name basis-0">
      {assignedNft && account?.nft_id ? (
        `#${account.nft_id}`
      ) : (
        <div className="d-inline-block position-relative">
          <img src={skynityIsland} />
          <i className="fa-solid fa-ban position-absolute" />
        </div>
      )}
    </div>
  </div>
);
