import {
  LPPairsContracts,
  TokenBalances,
  TokenData,
  TokenPrices,
  TokensData,
  TokenSymbol,
} from 'models';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { createAction } from '@reduxjs/toolkit';

export const getBalances = createAction<{
  tokensData: TokenData[];
  connectedAddress: string;
}>('GET_BALANCES');

export const getBalancesSuccess = createAction<TokenBalances>(
  'GET_BALANCES_SUCCESS'
);

export const getBalancesFailure = createAction('GET_BALANCES_FAILURE');

export const getTokensData = createAction<{ networkId: string; web3: Web3 }>(
  'GET_TOKENS_DATA'
);

export const getTokensDataSuccess = createAction<TokensData>(
  'GET_TOKENS_DATA_SUCCESS'
);

export const getTokensDataFailure = createAction('GET_TOKENS_DATA_FAILURE');

export const getNftTokenContract = createAction<{
  networkId: string;
  web3: Web3;
}>('GET_NFT_TOKEN_CONTRACT');

export const getNftTokenContractSuccess = createAction<Contract>(
  'GET_NFT_TOKEN_CONTRACT_SUCCESS'
);

export const getNftTokenContractFailure = createAction(
  'GET_NFT_TOKEN_CONTRACT_FAILURE'
);

export const getGameBridgeContract = createAction<{
  networkId: string;
  web3: Web3;
}>('GET_GAME_BRIDGE_CONTRACT');

export const getGameBridgeContractSuccess = createAction<Contract>(
  'GET_GAME_BRIDGE_CONTRACT_SUCCESS'
);

export const getGameBridgeContractFailure = createAction(
  'GET_GAME_BRIDGE_CONTRACT_FAILURE'
);

export const getTokensPrices = createAction<{
  stableTokenPrice: string;
  tokensData: Partial<Record<TokenSymbol, TokenData>>;
  stableTokenData: TokenData;
  LPPairsContracts: LPPairsContracts;
}>('GET_TOKENS_PRICES');

export const getTokensPricesSuccess = createAction<TokenPrices>(
  'GET_TOKENS_PRICES_SUCCESS'
);

export const getTokensPricesFailure = createAction('GET_TOKENS_PRICES_FAILURE');

export const resetTokensState = createAction('RESET_TOKENS_STATE');
