import { InfoCard } from 'components';
import { TokensData, VestingPoolData } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectLpPairsContracts,
  selectTokensData,
  selectVestingPools,
} from 'store';

import VestingPool from './VestingPool';

const VestingPools: React.FC = () => {
  const vestingPoolsData: VestingPoolData[] = useSelector(selectVestingPools);
  const tokensData: TokensData = useSelector(selectTokensData);
  const LPPairsContracts = useSelector(selectLpPairsContracts);
  const vestingPools = vestingPoolsData?.map(
    (vestingPoolData: VestingPoolData, index: number) => (
      <VestingPool
        key={`vesting-pool-component-${index}`}
        tokensData={tokensData}
        LPPairsContracts={LPPairsContracts}
        vestingPoolData={vestingPoolData}
      />
    )
  );

  return vestingPools?.length ? (
    <div className="vesting-pools">{vestingPools}</div>
  ) : (
    <InfoCard
      headerText="Vesting unavailable"
      description="This section does not seem to be applicable to your wallet address. Please change your wallet or go to different section"
    />
  );
};

export default VestingPools;
