import { canStake, getContract, skynityConfig } from 'helpers';
import {
  StakingPoolConfig,
  StakingPoolData,
  StakingPoolItemConfig,
  StakingPoolItemData,
} from 'models';
import { from, Observable } from 'rxjs';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

export class StakingService {
  public static getStakingPools(
    networkId: string,
    connectedAddress: string,
    web3: Web3,
    gasPrice: string
  ): Observable<StakingPoolData[]> {
    return from(
      Promise.all(
        skynityConfig.staking?.pools.map(
          async (stakingPool: StakingPoolConfig) => {
            const stakingPoolItems: StakingPoolItemData[] = await Promise.all(
              stakingPool.poolItems.map(
                async (
                  stakingPoolItem: StakingPoolItemConfig
                ): Promise<StakingPoolItemData> => {
                  const stakingContractInstance: Contract = getContract(
                    web3,
                    stakingPoolItem.stakingContractJSON,
                    networkId,
                    gasPrice
                  );
                  const addressCanStake = stakingPoolItem.hasWhitelist
                    ? await canStake(connectedAddress, stakingContractInstance)
                    : true;

                  return {
                    poolItemName: stakingPoolItem.poolItemName,
                    poolItemtype: stakingPoolItem.poolItemtype,
                    stakingPoolItemContract: stakingContractInstance,
                    addressCanStake,
                  };
                }
              )
            );

            return {
              poolType: stakingPool.poolType,
              //   poolSubtype: stakingPool.poolSubtype,
              poolName: stakingPool.poolName,
              poolDescription: stakingPool.poolDescription,
              poolStakingTokenSymbol: stakingPool.poolStakingTokenSymbol,
              poolItems: stakingPoolItems,
            };
          }
        )
      )
    );
  }
}
