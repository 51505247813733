import { VestingPoolData } from 'models';
import { TransactionReceipt } from 'web3-core';

export const vestingClaim = async (
  vestingPoolData: VestingPoolData,
  connectedAddress: string
): Promise<TransactionReceipt> => {
  // preflight check with "call" to return a possible error with reason message without posting transaction ("send" does not return a reason message in web3js 1.x)
  await vestingPoolData.vestingPoolContract.methods.claim().call({
    from: connectedAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  });

  return await vestingPoolData.vestingPoolContract.methods.claim().send({
    from: connectedAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  });
};
