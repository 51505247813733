import { NetworksParams } from 'config/networks';
import { isProduction } from 'helpers/environment.helper';
import { VestingPoolType } from 'helpers/vesting';
import {
  AllocationPoolConfig,
  AllocationPoolName,
  AllocationPoolSubtype,
  AllocationPoolType,
  AppConfig,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolType,
  TokenName,
  VestingPoolConfig,
} from 'models';
import {
  LPTokenSymbol,
  MainTokenSymbol,
  StableTokenSymbol,
} from 'models/token.model';

// ALLOCATION
import skynityAllocationPublicUSDCContractJSON from './contracts/allocation/SkynityAllocationVaultPublicUSDC.json';
// GAME BRIDGE
import gameBridgeContractJSON from './contracts/game-bridge/GameBridge.json';
// LP
import SDTUSDCLPPairContractJSON from './contracts/lp/LPPair.json';
import SDTUSDCtokenLPContractJSON from './contracts/lp/LPToken.json';
// NFT
import NFTContactJSON from './contracts/nft/NFTContract.json';
// TOKEN
import SDTtokenContractJSON from './contracts/sdt/SDTToken.json';
// STABLECOIN
import SCUSDCTokenJSON from './contracts/stable/SCUSDCToken.json';
// STAKING
import StakingLPSDTUSDCLiquid from './contracts/staking/liquid/Staking_LP_Liquid.json';
import StakingSDTLiquid from './contracts/staking/liquid/Staking_Sdt_Liquid.json';
import StakingLPSDTUSDCLocked3M from './contracts/staking/locked/Staking_SdtUsdc_Locked3m.json';
import StakingLPSDTUSDCLocked6M from './contracts/staking/locked/Staking_SdtUsdc_Locked6m.json';
// VESTING
import vestingWithBridge1 from './contracts/vesting/VestingWithBridge1.json';
import vestingWithBridge2 from './contracts/vesting/VestingWithBridge2.json';

const permittedNetworks = [
  NetworksParams.BaseMainnet,
  ...(isProduction() ? [] : [NetworksParams.BaseTestnet]),
];

const primaryNetwork = NetworksParams.BaseMainnet;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  // TODO: Update name, change contracts
  poolDescription: 'SDT-USDC',
  poolName: StakingPoolName.LP,
  poolStakingTokenSymbol: LPTokenSymbol.SDTUSDC,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: StakingLPSDTUSDCLiquid,
    },
    {
      poolItemName: '#DURATION# Locked',
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: StakingLPSDTUSDCLocked3M,
    },
    {
      poolItemName: '#DURATION# Locked',
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: StakingLPSDTUSDCLocked6M,
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: 'SDT',
  poolName: StakingPoolName.SA,
  poolStakingTokenSymbol: MainTokenSymbol.SDT,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: StakingSDTLiquid,
    },
    // {
    //   poolItemName: StakingPoolItemName.ThreeMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: StakingSDTLocked3M,
    //   lockPeriod: 3,
    // },
  ],
};

const pregamePublicAllocationPoolUSDC: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Public,
  poolDescription: 'USDC',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.USDC,
  poolItems: [
    {
      poolItemName: 'SkyNity pre-game $SDT public sale allocation (USDC)',
      poolItemContractJSON: skynityAllocationPublicUSDCContractJSON,
      hasWhitelist: false,
      isActive: true,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [LPStakingPool, SAStakingPool];
// TODO - change types
const activeVestingPools: VestingPoolConfig[] = [
  {
    poolType: VestingPoolType.Team,
    vestingContractJSON: vestingWithBridge1,
  },
  {
    poolType: VestingPoolType.Public,
    vestingContractJSON: vestingWithBridge2,
  },
];
const activeAllocationPools: AllocationPoolConfig[] = [
  pregamePublicAllocationPoolUSDC,
  // pregamePrivateAllocationPoolUSDT,
  // pregamePrivateAllocationPoolBUSD,
  // pregamePublicAllocationPoolBUSD,
  // pregameSeedAllocationPoolUSDT,
];

export const baseConfig: AppConfig = {
  skynityFee: '0.3%',
  minimumDepositSdt: 20,
  minimumWithdrawalSdt: 20,
  withdrawalFeeFraction: 0.02,
  withdrawalFeeMinSdt: 1,
  permittedNetworks,
  primaryNetwork,
  tokens: {
    [MainTokenSymbol.SDT]: {
      name: TokenName.Skynity,
      symbol: MainTokenSymbol.SDT,
      decimals: 18,
      tokenContractJSON: SDTtokenContractJSON,
    },
    [LPTokenSymbol.SDTUSDC]: {
      name: TokenName.LPSkynity,
      symbol: LPTokenSymbol.SDTUSDC,
      decimals: 18,
      tokenContractJSON: SDTUSDCtokenLPContractJSON,
    },
    [StableTokenSymbol.USDC]: {
      name: TokenName.Stablecoin,
      symbol: StableTokenSymbol.USDC,
      decimals: 6,
      tokenContractJSON: SCUSDCTokenJSON,
    },
  },
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
  allocation: {
    pools: activeAllocationPools,
  },
  gameBridgeContractJSON,
  nftContractJSON: NFTContactJSON,
  LPPairs: {
    // [LPTokenSymbol.SDTUSDT]: { contractJSON: SDTUSDTLPPairContractJSON },
    [LPTokenSymbol.SDTUSDC]: { contractJSON: SDTUSDCLPPairContractJSON },
  },
  additionalData: {
    tokenAddresses: {
      [MainTokenSymbol.SDT]: '0x0280484eCCa11b4e6C06754FbbCE8c67e2C683F6',
    },
    tokenChartLink:
      'https://www.geckoterminal.com/bsc/pools/0x7632626286c17475a91f39173c144cff9b115eca',
    tokenLink:
      'https://pancakeswap.finance/swap?inputCurrency=0x87133beCEa974278F6CBCAA32fcE03E24Dc97cE3&outputCurrency=0x5f39dd1bb6db20f3e792c4489f514794cac6392c',
    lpPairLink:
      'https://pancakeswap.finance/add/0x87133beCEa974278F6CBCAA32fcE03E24Dc97cE3/0x5f39DD1bB6Db20F3e792c4489F514794caC6392c',
    skyLandsNftMarketplaceLink:
      'https://opensea.io/collection/skylands-skynity',
    skyLandsNftBridgeLink: 'https://app.mintlabz.io/bridge',
  },
};
