import skynityLogo from 'assets/svg/skynity-logo.svg';
import {
  applyMask,
  isPermittedNetwork,
  RoutingPaths,
  skynityConfig,
  switchToChain,
} from 'helpers';
import React from 'react';
import { Button, Dropdown, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectConnectedAddress,
  selectConnectedNetwork,
  selectWeb3,
} from 'store';

interface HeaderProps {
  connectWallet?: () => void;
  disconnectWallet?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  connectWallet,
  disconnectWallet,
}) => {
  const connectedAddress = useSelector(selectConnectedAddress);
  const connectedNetwork = useSelector(selectConnectedNetwork);
  const web3 = useSelector(selectWeb3);

  const solePermittedNetworkActive =
    skynityConfig.permittedNetworks.length == 1 &&
    isPermittedNetwork(connectedNetwork?.chainId);

  return (
    <header className="header">
      <Navbar collapseOnSelect expand="md" variant="dark">
        <div className="container-fluid">
          <Navbar.Brand as={Link} to={RoutingPaths.Home}>
            <img src={skynityLogo} alt="Skynity logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-center flex-grow-1">
              <Nav.Link as={Link} to={RoutingPaths.Home}>
                Home
              </Nav.Link>
              <NavDropdown title="SDT Token" id="basic-nav-dropdown">
                {/* <NavDropdown.Item
                  href={skynityConfig.additionalData.tokenLink}
                  className="link secondary font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy
                </NavDropdown.Item> */}
                {/* <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  className="link secondary"
                  as={Link}
                  to={RoutingPaths.Staking}
                >
                  Staking
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="link secondary"
                  as={Link}
                  to={RoutingPaths.Vesting}
                >
                  Vesting
                </NavDropdown.Item>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown title="SkyLands" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href={skynityConfig.additionalData.skyLandsNftMarketplaceLink}
                  target="_blank"
                  className="link-external"
                >
                  Marketplace
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={skynityConfig.additionalData.skyLandsNftBridgeLink}
                  target="_blank"
                  className="link-external"
                >
                  Bridge
                </NavDropdown.Item>
              </NavDropdown>

              {/* TODO: enable when ready*/}
              <NavDropdown title="Pregame Sale" id="basic-nav-dropdown">
                {/* <NavDropdown.Item
                  className="link secondary font-bold"
                  as={Link}
                  to={RoutingPaths.PrivateSale}
                  disabled={true}
                >
                  Private
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  className="link secondary font-bold"
                  as={Link}
                  to={RoutingPaths.PublicSale}
                >
                  Public
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to={RoutingPaths.Settings}>
                Game panel
              </Nav.Link>
            </Nav>
            <Nav className="wallet-section">
              {connectedAddress && (
                <>
                  <NavDropdown
                    className={`font-small font-bold ${
                      isPermittedNetwork(connectedNetwork?.chainId)
                        ? 'secondary'
                        : 'danger'
                    } nowrap`}
                    title={applyMask(connectedAddress)}
                  >
                    <i
                      className="fa fa-power-off pointer"
                      onClick={disconnectWallet}
                      title="Disconnect wallet"
                    ></i>
                    <NavDropdown.Header>
                      <Dropdown className="chain-selector">
                        {solePermittedNetworkActive ? (
                          <Button>{connectedNetwork?.name}</Button>
                        ) : (
                          <>
                            <Dropdown.Toggle
                              variant={
                                isPermittedNetwork(connectedNetwork?.chainId)
                                  ? undefined
                                  : 'danger'
                              }
                            >
                              {isPermittedNetwork(connectedNetwork?.chainId)
                                ? connectedNetwork?.name
                                : 'Wrong network'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {skynityConfig.permittedNetworks.map(
                                (network) => (
                                  <Dropdown.Item
                                    key={network.chainId}
                                    disabled={
                                      network.chainId ===
                                      connectedNetwork?.chainId
                                    }
                                    onClick={(): void => {
                                      switchToChain(web3, network.chainId);
                                    }}
                                  >
                                    {network.addChainParams?.chainName}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </>
                        )}
                      </Dropdown>{' '}
                    </NavDropdown.Header>

                    <NavDropdown.Item
                      href="https://opensea.io/account/private"
                      target="_blank"
                      className="link-external"
                    >
                      My SkyLands NFTs
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
              {!connectedAddress && (
                <button
                  className="btn btn-secondary nowrap"
                  onClick={connectWallet}
                >
                  Connect wallet
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};
