import { toWei } from 'helpers';
import { StakingPoolItemData } from 'models';

export const postStake = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingValue: string,
  decimals: number,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<unknown> => {
  const stakingValueWei = toWei(stakingValue, decimals);

  return await stakingPoolItemData.stakingPoolItemContract.methods
    .stake(stakingValueWei)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};
