import { AppChain, skynityConfig } from 'helpers';
import Web3 from 'web3';
import { NetworksParams } from '../config';
import { AppChainGroup, evmChains } from './config.helper';

export enum NoConnectionType {
  WrongNetwork = 'wrong-network',
  WalletNotConnected = 'wallet-not-connected',
}

export const isPermittedNetwork = (chainId: number): boolean =>
  !!skynityConfig.permittedNetworks.some(
    (network) => network.chainId == chainId
  );

export const isChain = (appChain: AppChain | AppChainGroup): boolean => {
  const isEvmChain = evmChains.includes(
    process.env.REACT_APP_CHAIN as AppChain
  );

  return appChain === AppChainGroup.Evm
    ? isEvmChain
    : process.env.REACT_APP_CHAIN === appChain;
};

export const getChainName = (appChain: AppChain): string => {
  switch (appChain) {
    case AppChain.Bsc: {
      return 'BNB Smart Chain';
    }

    case AppChain.Base: {
      return 'Base Network';
    }
    default: {
      throw new Error('Unsupported Chain');
    }
  }
};

export const switchToChain = async (
  web3: Web3,
  chainId: number | string
): Promise<void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await (web3.currentProvider as any).request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: web3.utils.toHex(chainId),
        },
      ],
    });
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask
    if ((error as { code?: number }).code === 4902) {
      const targetNetworkParams = Object.values(NetworksParams).find(
        (network) => network.chainId == chainId
      )?.addChainParams;
      if (targetNetworkParams) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (web3.currentProvider as any).request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...targetNetworkParams,
              chainId: web3.utils.toHex(chainId),
            },
          ],
        });
      }
    }
  }
};

export const switchToPrimaryChain = async (web3: Web3): Promise<void> => {
  await switchToChain(web3, skynityConfig.primaryNetwork.chainId);
};

export const addTokenToWallet = async (
  web3: Web3,
  networkId: number
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await (web3.currentProvider as any).request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        address: (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
          networkId
        ]?.address,
        // symbol: skynityConfig.tokens.SDT.symbol,
        // decimals: skynityConfig.tokens.SDT.decimals,
        // image: 'https://foo.io/token-image.svg',
      },
    },
  });
};
