import { useEffect, useRef, useState } from 'react';

// Please refer to EIP-6963 specs: https://eips.ethereum.org/EIPS/eip-6963
// Declare a global interface to extend the WindowEventMap with a custom event "eip6963:announceProvider"
declare global {
  interface WindowEventMap {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'eip6963:announceProvider': EIP6963AnnounceProviderEvent;
  }
}

// Define a class for the "eip6963:requestProvider" event
export class EIP6963RequestProviderEvent extends Event {
  constructor() {
    super('eip6963:requestProvider');
  }
}

// Define an interface for the "eip6963:announceProvider" event
export interface EIP6963AnnounceProviderEvent extends Event {
  type: 'eip6963:announceProvider';
  detail: EIP6963ProviderDetail;
}

// Define an interface for the provider details
export interface EIP6963ProviderDetail {
  info: EIP6963ProviderInfo;
  provider: EIP1193Provider;
}

// Define an interface for the provider information
export interface EIP6963ProviderInfo {
  uuid: string; // Unique identifier of the wallet extension announcement, keep in mind it changes on every request-announcement cycle
  name: string; // Name of the wallet extension
  icon: string; // Icon for the wallet extension
  rdns: string; // Reverse DNS name of the wallet extension
}

// Define an interface for the EIP1193 provider.
// It's the same interface we are used to access with 'window.ethereum'
export interface EIP1193Provider {
  request(request: {
    method: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: Array<any> | Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any>;
}

export const useEip6963Providers = (): EIP6963ProviderDetail[] => {
  const [providers, setProviders] = useState<EIP6963ProviderDetail[]>([]);
  const providersRef = useRef<EIP6963ProviderDetail[]>([]);
  const setProvidersRef =
    useRef<React.Dispatch<React.SetStateAction<EIP6963ProviderDetail[]>>>();

  useEffect(() => {
    providersRef.current = providers;
    setProvidersRef.current = setProviders;
  });

  useEffect(() => {
    const onAnnounceProvider = (event: EIP6963AnnounceProviderEvent): void => {
      if (
        // !providersRef.current.some((p) => p.info.uuid === event.detail.info.uuid)
        !providersRef.current.some((p) => p.provider === event.detail.provider)
      ) {
        providersRef.current.push(event.detail);
      }
    };
    window.addEventListener('eip6963:announceProvider', onAnnounceProvider);

    window.dispatchEvent(new EIP6963RequestProviderEvent());

    return () => {
      window.removeEventListener(
        'eip6963:announceProvider',
        onAnnounceProvider
      );
    };
  }, []);

  return providers;
};

/*
https://github.com/nfwsncked/eip-6963-tutorial/tree/main/src/injected-wallet-provider
https://medium.com/blockapex/simplifying-eip-6963-5e9c78d3e10d
*/
