import { SectionHeader } from 'components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectBalances,
  selectConnectedAddress,
  selectConnectedNetwork,
  selectStablecoinPrice,
} from 'store';

import StakingPools from './StakingPools';
import { skynityConfig } from '../../helpers';
import { Modal } from 'react-bootstrap';

export const Staking: React.FC = () => {
  const stableTokenPrice = useSelector(selectStablecoinPrice);
  const connectedNetwork = useSelector(selectConnectedNetwork);
  const connectedAddress = useSelector(selectConnectedAddress);
  const balances = useSelector(selectBalances);
  const [tokenAddress, setTokenAddress] = React.useState<string>();

  const [showModal, setShowModal] = React.useState<boolean>(false);

  useEffect(() => {
    if (!connectedNetwork) return;
    setTokenAddress(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
        connectedNetwork.chainId
      ]?.address
    );
  }, [connectedNetwork]);

  return (
    <>
      {connectedAddress ? (
        <div className="container staking">
          <div className="container-inner">
            <div className="section-container">
              <SectionHeader title="Staking">
                <div className="mb-2">
                  Stake SDT tokens or LP (Liquidity Provider) tokens in one of
                  the below staking pools to earn rewards.
                </div>
                <div className="mb-2">
                  Staking LP tokens generally offers higher rewards than SDT
                  tokens and they can be obtained by providing liquidity for
                  SDT-USDC pair on Uniswap decentralized exchange.{' '}
                  <a
                    className="link link-primary pointer"
                    onClick={(): void => setShowModal(true)}
                  >
                    Read more...
                  </a>
                </div>
              </SectionHeader>
            </div>

            {stableTokenPrice && balances && <StakingPools />}
          </div>
        </div>
      ) : (
        ''
      )}
      <Modal show={showModal} backdrop="static" centered>
        <Modal.Header className="justify-center">
          Getting LP tokens
        </Modal.Header>
        <Modal.Body className="text-break">
          To provide liquidity and obtain LP tokens:
          <ul className="mb-0">
            <li>
              Go to Uniswap V2{' '}
              <a
                href="https://app.uniswap.org/positions/create/v2"
                target="_blank"
                rel="noreferrer"
                className="link link-primary link-external"
              >
                New position
              </a>{' '}
              page.
            </li>
            <li>
              In the first token dropdown select &quot;Base&quot; network on the
              right side of the &quot;Search tokens&quot; input, paste SDT token
              address{' '}
              {/* <span className="primary">{tokenAddress}</span>{' '} */}
              <a
                className="link link-primary pointer"
                onClick={(): void => {
                  navigator.clipboard.writeText(tokenAddress);
                }}
                title="Copy to clipboard"
              >
                {tokenAddress}
                <i className="fa-regular fa-copy ms-1" />
              </a>{' '}
              in this input, and select the token.
            </li>
            <li>
              In the second token dropdown enter &quot;USDC&quot; in the search
              field and select the token.
            </li>
            <li>
              Click on &quot;Continue&quot; and proceed with the further
              instructions.
            </li>
          </ul>
          More information can be found{' '}
          <a
            href="https://support.uniswap.org/hc/en-us/articles/20915582427917-How-to-add-liquidity-to-Uniswap-v2"
            target="_blank"
            rel="noreferrer"
            className="link link-primary link-external"
          >
            here
          </a>
          .
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary w-100"
            onClick={(): void => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
