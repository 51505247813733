import { DataItem } from 'components';
import {
  MainTokenSymbol,
  StakingPoolItemStakingData,
  TokenSymbol,
} from 'models';
import React from 'react';

interface StakingPoolItemDetailsProps {
  balance: string;
  staking: StakingPoolItemStakingData;
  symbol: TokenSymbol;
}

export const StakingPoolItemDetails: React.FC<StakingPoolItemDetailsProps> = ({
  balance,
  staking,
  symbol,
}) => (
  <div className="staking-pool-item-details">
    <DataItem
      label="In wallet"
      data={balance}
      symbol={symbol}
      iconClasses={'fa fa-wallet'}
    />
    <DataItem
      label="Staked"
      data={staking.stakedTokens}
      symbol={symbol}
      iconClasses={'fa fa-cubes-stacked'}
    />
    <DataItem
      label="Claimable rewards"
      data={staking.rewardTokens}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-medal'}
    />
  </div>
);
