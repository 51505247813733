import { Message, SectionHeader, SectionLoader } from 'components';
import { isUnassignedNft, MessageType, skynityConfig } from 'helpers';
import { GameServer, Nft } from 'models';
import React from 'react';

import { NftItem } from './Nft';

export interface NftsProps {
  gameServers: GameServer[];
  nfts: Nft[];
  error: string;
}

export const Nfts: React.FC<NftsProps> = ({ nfts, gameServers, error }) => {
  const unassignedNftItems = nfts?.reduce((acc: JSX.Element[], nft: Nft) => {
    const isUnassigned = isUnassignedNft(gameServers, nft);

    if (isUnassigned) {
      acc.push(<NftItem key={nft.edition} nft={nft} />);
    }

    return acc;
  }, []);

  return (
    <div className="section-container">
      <div className="nfts">
        <SectionHeader
          title="Your unassigned SkyLands NFTs"
          description="Below you can find all your NFT SkyLands that have not been assigned to a game account."
        />

        {unassignedNftItems?.length && !error ? (
          <div className="nft-items">{unassignedNftItems}</div>
        ) : (
          ''
        )}

        {!unassignedNftItems && !error ? (
          <SectionLoader />
        ) : (
          <>
            <a
              href={skynityConfig.additionalData.skyLandsNftMarketplaceLink}
              target="_blank"
              rel="noreferrer"
              className="link link-primary link-external d-block text-center mt-32 mx-5"
            >
              Get SkyLands NFTs
            </a>
            <a
              href={skynityConfig.additionalData.skyLandsNftBridgeLink}
              target="_blank"
              rel="noreferrer"
              className="link link-primary link-external d-block text-center mt-8 mx-5"
            >
              Bridge your NFTs from BNB Smart Chain network
            </a>
          </>
        )}

        {error ? (
          <Message
            messageType={MessageType.Error}
            descriptionText={error}
          ></Message>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
