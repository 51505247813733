import { MessageType } from 'helpers';
import React, { PropsWithChildren, useState } from 'react';

export const Message: React.FC<
  PropsWithChildren<{
    messageType?: MessageType;
    descriptionText?: string;
    headerText?: string;
    showCloseButton?: boolean;
  }>
> = ({
  descriptionText,
  messageType,
  headerText,
  showCloseButton,
  children,
}) => {
  const [open, setOpen] = useState(true);

  showCloseButton =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    showCloseButton === true ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    (messageType === MessageType.Error && showCloseButton !== false);

  return (
    <div
      className={`message ${messageType} position-relative${
        open ? '' : ' d-none'
      }`}
    >
      {headerText && <div className="message-header">{headerText}</div>}
      <div className={`message-description`}>
        {descriptionText} <span>{children}</span>
      </div>
      {showCloseButton && (
        <i
          className="fa fa-xmark position-absolute top-0 end-0 mt-2 me-2 pointer"
          onClick={(): void => setOpen(false)}
        ></i>
      )}
    </div>
  );
};
